import { Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import Button from "../../../MaterialUI/Button";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { Invoice, EditInvoice as EditInvoiceType, webkitAutofillStyles } from "./InvoiceTypes";
import * as yup from 'yup';
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { Formik } from "formik";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { useAuthedContext } from "../../../../context/AuthContext";
import Select from "../../../MaterialUI/FormFields/Select";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { getQueryLatestSupplier, getQueryStatusInvoices } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import callApi from "../../../../Api/callApi";
import ImagePreview from "./imagePreview";
import Modal from "../../../MaterialUI/Modal";
import CreateSupplier from "./CreateSupplier";
import { postQueryUploadInvoice } from "../../../../pages/Finance/API/apiFinancePostQueries";

const fieldValidation = yup.object({ // TO DO
  invoice_number: YUP_REQUIRED_STRING,
  supplier_name: YUP_REQUIRED_STRING,
  supplier_vat_number: YUP_REQUIRED_STRING,
  status: YUP_REQUIRED_STRING,
});

interface SelectOption {
  value: string;
  description: string;
  id?: string;
}

interface EditInvoiceProps {
  invoice: Invoice;
  onSubmit?: (values: Invoice) => void;
}

const EditInvoice: React.FC<EditInvoiceProps> = ({
  invoice,
  onSubmit
}) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<SelectOption[]>([]); // dropdown for supplier names
  const [statusDropdownOptions, setStatusDropdownOptions] = useState<SelectOption[]>([]); // dropdown for status
  const [supplierModalOpen, setSupplierModalOpen] = useState<boolean>(false); // modal for supplier
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [supplierId, setSupplierId] = useState<string | null>(null);

  console.log(alertMessage);

  const transformInvoiceToEditInvoice = (invoice: Invoice): EditInvoiceType => {
    return {
      document_id: invoice.document_id,
      invoice_data: {
        invoice_type: 'incoming',
        invoice_number: invoice.invoice_number,
        date: invoice.date || '',
        net_total: invoice.net_total,
        discount: invoice.discount !== null ? Number(invoice.discount) : 0,
        supplier_id: supplierId || '',
        supplier_name: invoice.supplier_name,
        supplier_vat_number: invoice.supplier_vat_number,
        customer_id: '',
        customer_name: invoice.customer_name,
        customer_vat_number: invoice.customer_vat_number,
        description: '',
        quantity: invoice.quantity || 0,
        unit_price: invoice.unit_price !== null ? Number(invoice.unit_price) : 0,
        vat_amount: invoice.vat_amount !== null ? Number(invoice.vat_amount) : 0,
        total_excise_amount: invoice.total_excise_amount !== null ? Number(invoice.total_excise_amount) : 0,
        currency: invoice.currency || '',
        deal_terms: '',
        comments: '',
        reference_id: invoice.reference_id || '',
        purchase_order_id: invoice.purchase_order_id || '',
        sales_order_id: invoice.sales_order_id || '',
        status: invoice.status,
        due_date: invoice.due_date || '',
        total: invoice.total !== null ? Number(invoice.total) : 0,
        file_name: invoice.file_name,
        products: [
          {
            product_id: '672345397de105eec15ae7d1', // fetch all material master data endpoint - random id
            quantity: 0,
          }
        ]
      },
    };
  };

  useEffect(() => {
    setSupplierNames();
    setStatusInfo();
  }, []);

  const setSupplierNames = () => {
    const options = invoice.supplier_matches
      ? invoice.supplier_matches.map((match: any) => ({
        value: match.name,
        description: match.name,
        id: match.id
      }))
      : [];

    setDropdownOptions(options);

    // Set initial supplier ID if there's a default selected supplier
    if (invoice.supplier_matches?.[0]?.id) {
      setSupplierId(invoice.supplier_matches[0].id);
    }
  };

  const setStatusInfo = async () => { // set status info in the dropdown
    const statusResponse = await callApi<any>({
      query: getQueryStatusInvoices(),
      auth: { setAuthedUser }
    })

    const statusArray = statusResponse.statuses.map((status: any) => status.value);
    setStatusDropdownOptions(handleGetSelectOption(statusArray));
  }

  const getNewSupplier = async (setFieldValue: any) => {
    try {
      const response = await callApi<any>({
        query: getQueryLatestSupplier(),
        auth: { setAuthedUser },
      });

      const newEntry = response.party.name;

      // Check if the new entry already exists in the dropdown options
      const isDuplicate = dropdownOptions.some(option => option.value === newEntry);

      if (!isDuplicate) {
        // Create a new option object for the dropdown
        const newOption: SelectOption = { value: newEntry, description: newEntry };

        // Update the dropdown options by adding the new option
        const updatedOptions = [...dropdownOptions, newOption];

        // Update the state with the new options
        setDropdownOptions(updatedOptions);

        if (setFieldValue) {
          setFieldValue("supplier_name", newEntry);
        }

      } else {
        console.warn("Supplier already exists in the dropdown options.");
      }
    } catch (error) {
      console.error("Failed to fetch new supplier:", error);
    }
  };

  const handleFormSubmit = async (values: Invoice) => {
    try {
      const editInvoiceInitial: EditInvoiceType = transformInvoiceToEditInvoice(values);

      if (onSubmit) {
        const post = await callApi<any>({
          query: postQueryUploadInvoice(editInvoiceInitial),
          auth: { setAuthedUser },
        });

        onSubmit(post);
      }
    } catch (err) {
      console.log("UpdateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const sendId = (data: string) => {
    setSupplierId(data);
  }

  const handleOpenModal = () => {
    setModalTitle("Create New Supplier");
    setSupplierModalOpen(true);
  };

  const handleCloseModal = () => {
    setSupplierModalOpen(false);
    setModalTitle(null);
  };

  return (
    <>
      <ImagePreview file_id={invoice.document_id} />

      <Formik
        initialValues={{
          ...invoice,
          supplier_name: (invoice.supplier_matches?.[0]?.name) || "",
        }}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({ handleSubmit, handleChange, setFieldValue, touched, errors, values }) => (
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>

                <Grid container item xs={12} md={12} sx={{ justifyContent: 'space-between' }}>
                  <Typography variant="h6">
                    {t("Supplier Information")}
                  </Typography>

                  <IconButton
                    aria-label="add new supplier"
                    onClick={handleOpenModal}
                    sx={{ padding: 0 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>

                <Modal
                  open={supplierModalOpen}
                  onClose={() => setSupplierModalOpen(false)}
                  label={modalTitle}
                >
                  {modalTitle === "Create New Supplier" && (
                    <CreateSupplier onSubmit={async () => {
                      setSupplierModalOpen(false);
                      handleCloseModal();
                      await getNewSupplier(setFieldValue);

                    }} sendId={sendId} />
                  )}
                </Modal>

                <Select
                  fullWidth
                  name="supplier_name"
                  label={t("Supplier Name")}
                  value={values.supplier_name}
                  onChange={(e) => {
                    handleChange(e);
                    const selectedOption = dropdownOptions.find(
                      option => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setSupplierId(selectedOption.id || null);
                    }
                  }}
                  selectOptions={dropdownOptions}
                  error={touched.supplier_name && !!errors.supplier_name}
                  helperText={touched.supplier_name && errors.supplier_name}
                />

                <TextField
                  name="supplier_vat_number"
                  label={t("Supplier VAT Number")}
                  error={
                    touched["supplier_vat_number"] && !!errors["supplier_vat_number"]
                  }
                  helperText={
                    touched["supplier_vat_number"] && errors["supplier_vat_number"]
                  }
                  onChange={handleChange}
                  value={values.supplier_vat_number}
                  sx={{ marginTop: "5px" }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{
                    sx: { fontSize: 14 },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography sx={{ paddingBottom: "6px" }} variant="h6">
                  {t("Invoice Details")}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="invoice_number"
                      label={t("Invoice Number")}
                      error={touched["invoice_number"] && !!errors["invoice_number"]}
                      helperText={
                        touched["invoice_number"] && errors["invoice_number"]
                      }
                      onChange={handleChange}
                      value={values.invoice_number}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="date"
                      label={t("Invoice Date")}
                      error={touched["date"] && !!errors["date"]}
                      helperText={touched["date"] && errors["date"]}
                      onChange={handleChange}
                      value={values.date}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid sx={{ marginTop: -1 }} item xs={6}>
                    <TextField
                      name="due_date"
                      label={t("Due Date")}
                      error={touched["due_date"] && !!errors["due_date"]}
                      helperText={touched["due_date"] && errors["due_date"]}
                      onChange={handleChange}
                      value={values.due_date}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid sx={{ marginTop: -1 }} item xs={6}>
                    <Select
                      fullWidth
                      name="status"
                      label={t("Status")}
                      onChange={handleChange}
                      selectOptions={statusDropdownOptions}
                      value={values.status}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                      error={touched.status && !!errors.status}
                      helperText={touched.status && errors.status}
                    />
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} md={12}>
                <Typography sx={{ paddingBottom: "5px", paddingTop: "8px" }} variant="h6">
                  {t("Pricing Details")}
                </Typography>

                <Grid sx={{ justifyContent: 'space-between' }} container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      name="net_total"
                      label={t("Net Amount")}
                      error={touched["net_total"] && !!errors["net_total"]}
                      helperText={touched["net_total"] && errors["net_total"]}
                      onChange={handleChange}
                      value={values.net_total}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="currency"
                      label={t("Currency")}
                      error={touched["currency"] && !!errors["currency"]}
                      helperText={touched["currency"] && errors["currency"]}
                      onChange={handleChange}
                      value={values.currency}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="discount"
                      label={t("Discount")}
                      error={touched["discount"] && !!errors["discount"]}
                      helperText={touched["discount"] && errors["discount"]}
                      onChange={handleChange}
                      value={values.discount}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      name="vat_amount"
                      label={t("VAT Amount")}
                      error={touched["vat_amount"] && !!errors["vat_amount"]}
                      helperText={touched["vat_amount"] && errors["vat_amount"]}
                      onChange={handleChange}
                      value={values.vat_amount}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name="total"
                      label={t("Total")}
                      error={touched["total"] && !!errors["total"]}
                      helperText={touched["total"] && errors["total"]}
                      onChange={handleChange}
                      value={values.total}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center", }} xs={12}>
              <Button sx={{ marginTop: 4 }} type="submit" loading={formStatus === "loading"}>
                Update Invoice
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditInvoice;