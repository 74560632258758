import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../../MaterialUI/Button";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useLanguageContext } from "../../../../context/LanguageContext";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { Formik } from "formik";
import { object } from "yup";
// import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { EditInvoice, webkitAutofillStyles } from "./InvoiceTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import ProductTable from "./ProductTable";
import callApi from "../../../../Api/callApi";
import { getQueryCustomers, getQueryInvoiceNumeration, getQueryOurInformation, getQuerySuppliers } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import { postQueryUploadInvoice } from "../../../../pages/Finance/API/apiFinancePostQueries";
import Autocomplete from "@mui/material/Autocomplete";

const fieldValidation = object({
  // customer_name: YUP_REQUIRED_STRING,
  // customer_vat_number: YUP_REQUIRED_STRING,
  // currency: YUP_REQUIRED_STRING,
  // date: YUP_REQUIRED_STRING,
  // due_date: YUP_REQUIRED_STRING,
  // performance_period: YUP_REQUIRED_STRING
});

const invoiceInitial: EditInvoice = {
  document_id: '65b9d32f3f8a4c1a9e6d2a82',
  invoice_data: {
    invoice_type: '',
    invoice_number: '',
    date: '',
    net_total: 0,
    discount: 0,
    supplier_id: '',
    supplier_name: '',
    supplier_vat_number: '',
    customer_id: '',
    customer_name: '',
    customer_vat_number: '',
    description: '',
    quantity: 0,
    unit_price: 0,
    vat_amount: 0,
    total_excise_amount: 0,
    currency: '',
    deal_terms: '',
    comments: '',
    reference_id: '',
    purchase_order_id: '',
    sales_order_id: '',
    status: 'open',
    due_date: '',
    total: 0,
    file_name: '',
    payment_terms: '',
    bank_account: '',
    issue_place: '',
    created_by: '',
    receiver: '',
    performance_period: '',
    products: [
      {
        product_id: '672345397de105eec15ae7d1', // fetch all material master data endpoint - random id
        quantity: 0,
      }
    ]
  }
};

interface SelectOption {
  value: string;
  description: string;
  id?: string;
  taxId?: string;
  registrationNumber?: string;
}

interface EditInvoiceProps {
  onSubmit?: (values: EditInvoice) => void;
  tabView: "incoming" | "outgoing";
}


const CreateInvoice: React.FC<EditInvoiceProps> = ({
  onSubmit,
  tabView
}) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const [invoice, setInvoice] = useState<EditInvoice>(invoiceInitial);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [, setAlertMessage] = useState<string | null>(null);
  const [supplierDropdown, setSupplierDropdown] = useState<SelectOption[]>([]); // dropdown for supplier names
  // const [supplierId, setSupplierId] = useState<string | null>(null); // supplier id
  const [customerDropdown, setCustomerDropdown] = useState<SelectOption[]>([]); // dropdown for customer names


  useEffect(() => {
    setFormStatus(null); // remove it
    setInvoiceNumeration();

    if (tabView === 'incoming') {
      getAllSuppliers(); // suppliers dropdown for incoming
      getDefaultCustomerInformation(); // default customer info for incoming
    } else {
      getAllCustomers(); // customers dropdown for outgoing
      getDefaultSupplierInformation(); // default supplier info for outgoing
    }
  }, [tabView]);

  const handleFormSubmit = async (values: EditInvoice) => {
    try {
      console.log('111111 values', values)

      if (onSubmit) {
        const post = await callApi<any>({
          query: postQueryUploadInvoice(values),
          auth: { setAuthedUser },
        });

        onSubmit(post);
      }

    } catch (err) {
      console.log("UpdateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const setInvoiceNumeration = async () => {
    try {
      let invoiceNum: any = { next_invoice_number: '' };
      if (tabView === 'outgoing') {
        invoiceNum = await callApi<any>({
          query: getQueryInvoiceNumeration(),
          auth: { setAuthedUser }
        });
      }

      // Fetch supplier information for both "incoming" and "outgoing"
      // let supplierInfo = supplierDropdown.find(s => s.id === supplierId);

      setInvoice(prev => ({
        ...prev,
        invoice_data: {
          ...prev.invoice_data,
          invoice_number: tabView === 'outgoing' ? invoiceNum.next_invoice_number : '',
          invoice_type: tabView,
          // supplier_id: supplierId || '',
          // supplier_name: supplierInfo?.value || '',
          // supplier_vat_number: supplierInfo?.taxId || '',
          // reference_id: supplierInfo?.registrationNumber || '',
        }
      }));

    } catch (error) {
      console.error('Error fetching invoice number:', error);
    }
  };


  const getAllSuppliers = async () => { // for incoming invoices
    const suppliers = await callApi<any>({
      query: getQuerySuppliers(),
      auth: { setAuthedUser }
    });

    const supplierOptions: SelectOption[] = suppliers.rows.map((supplier: { party: { name: string; id: string; tax_id: string; registration_number: string }; id: string }) => ({
      value: supplier.party.name,
      description: supplier.party.name,
      id: supplier.id,
      taxId: supplier.party.tax_id,
      registrationNumber: supplier.party.registration_number
    }));

    setSupplierDropdown(supplierOptions);
  }

  const getAllCustomers = async () => { // for outgoing invoices
    const customers = await callApi<any>({
      query: getQueryCustomers(),
      auth: { setAuthedUser }
    });

    const customerOptions: SelectOption[] = customers.rows.map((customer: {
      name: string;
      id: string;
      tax_id: string;
    }) => ({
      value: customer.name,
      description: customer.name,
      id: customer.id,
      taxId: customer.tax_id
    }));

    setCustomerDropdown(customerOptions);
  }

  const getDefaultCustomerInformation = async () => { // for incoming invoices sets the default Evnisoft field
    const customerId = '1f58e06d-401d-45c2-be60-9998cd5409ff';

    try {
      const customerInformation = await callApi<any>({
        query: getQueryOurInformation(customerId),
        auth: { setAuthedUser }
      });

      const customerOptions: SelectOption[] = [
        {
          value: customerInformation.party.name,
          description: customerInformation.party.name,
          id: customerInformation.id,
          taxId: customerInformation.party.tax_id
        }
      ];      

      setCustomerDropdown(customerOptions);

      // Pre-select the first customer
      const firstCustomer = customerOptions[0];
      if (firstCustomer) {
        setInvoice(prev => ({
          ...prev,
          invoice_data: {
            ...prev.invoice_data,
            customer_id: firstCustomer.id || '',
            customer_name: firstCustomer.value || '',
            customer_vat_number: firstCustomer.taxId || ''
          }
        }));
      }
    } catch (error) {
      console.error('Error fetching customer information:', error);
    }
  }

  const getDefaultSupplierInformation = async () => {
    const supplierId = '52347d42-f524-43f3-8678-4e9d37842b99'; // incoming default supplier ID

    try {
      const supplierInformation = await callApi<any>({
        query: getQueryOurInformation(supplierId),
        auth: { setAuthedUser }
      });

      const supplierOptions: SelectOption[] = [{
        value: supplierInformation.party.name,
        description: supplierInformation.party.name,
        id: supplierInformation.id,
        taxId: supplierInformation.party.tax_id,
        registrationNumber: supplierInformation.party.registration_number
      }];

      setSupplierDropdown(supplierOptions);

      // Pre-select the first supplier
      const firstSupplier = supplierOptions[0];

      if (firstSupplier) {
        setInvoice(prev => ({
          ...prev,
          invoice_data: {
            ...prev.invoice_data,
            supplier_id: firstSupplier.id || '',
            supplier_name: firstSupplier.value || '',
            supplier_vat_number: firstSupplier.taxId || '',
            reference_id: firstSupplier.registrationNumber || ''
          }
        }));
        // setSupplierId(firstSupplier.id || null);
      }
    } catch (error) {
      console.error('Error fetching supplier information:', error);
    }
  };

  return (
    <Formik
      initialValues={invoice}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, setFieldValue, touched, errors, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {tabView !== 'incoming' && <Grid item xs={12} md={6}>
              <Typography sx={{ paddingBottom: "5px", paddingTop: "8px" }} variant="h6">
                {t("Customer Information")}
              </Typography>
              <Grid item xs={12}>
                <Autocomplete
                  options={customerDropdown}
                  getOptionLabel={(option) => option.value || ''}
                  value={customerDropdown.find(option => option.value === values.invoice_data?.customer_name) || null}
                  onChange={(_event, newValue) => {
                    setFieldValue("invoice_data.customer_name", newValue?.value || '');
                    setFieldValue("invoice_data.customer_id", newValue?.id || '');
                    setFieldValue("invoice_data.customer_vat_number", newValue?.taxId || '');
                  }}
                  // disabled={tabView === 'incoming'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="invoice_data.customer_name"
                      label="Customer Name"
                      error={touched.invoice_data?.customer_name && !!errors.invoice_data?.customer_name}
                      helperText={touched.invoice_data?.customer_name && errors.invoice_data?.customer_name}
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: tabView === 'incoming' ? null : params.InputProps.endAdornment,
                        sx: { fontSize: 14, ...webkitAutofillStyles }
                      }}
                      InputLabelProps={{
                        sx: { fontSize: 14 }
                      }}
                    // disabled={tabView === 'incoming'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="invoice_data.customer_vat_number"
                  label="Customer VAT Number"
                  error={touched.invoice_data?.customer_vat_number && !!errors.invoice_data?.customer_vat_number}
                  helperText={touched.invoice_data?.customer_vat_number && errors.invoice_data?.customer_vat_number}
                  onChange={handleChange}
                  value={values.invoice_data?.customer_vat_number || ''}
                  sx={{ marginTop: '10px' }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{
                    sx: { fontSize: 14 },
                  }}
                // disabled={tabView === 'incoming' && true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="invoice_data.currency"
                  label={t("Currency")}
                  error={touched.invoice_data?.currency && !!errors.invoice_data?.currency}
                  helperText={touched.invoice_data?.currency && errors.invoice_data?.currency}
                  onChange={handleChange}
                  value={values.invoice_data?.currency || ''}
                  sx={{ marginTop: '10px' }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{
                    sx: { fontSize: 14 },
                  }}
                />
              </Grid>
            </Grid>}

            {tabView !== "outgoing" && <Grid item xs={12} md={6}>
              <Typography sx={{ paddingBottom: "5px", paddingTop: "8px" }} variant="h6">
                {t("Supplier Information")}
              </Typography>

              <Grid item xs={12}>
                <Autocomplete
                  options={supplierDropdown}
                  getOptionLabel={(option) => option.value || ''}
                  value={supplierDropdown.find(option => option.value === values.invoice_data?.supplier_name) || null}
                  onChange={(_event, newValue) => {
                    setFieldValue("invoice_data.supplier_name", newValue?.value || '');
                    setFieldValue("invoice_data.supplier_id", newValue?.id || '');
                    setFieldValue("invoice_data.supplier_vat_number", newValue?.taxId || '');
                    setFieldValue("invoice_data.reference_id", newValue?.registrationNumber || '');
                    // setSupplierId(newValue?.id || '');
                  }}
                  // disabled={tabView === 'outgoing'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="invoice_data.supplier_name"
                      label={t("Supplier Name")}
                      error={touched.invoice_data?.supplier_name && !!errors.invoice_data?.supplier_name}
                      helperText={touched.invoice_data?.supplier_name && errors.invoice_data?.supplier_name}
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: tabView === 'outgoing' ? null : params.InputProps.endAdornment,
                        sx: { fontSize: 14, ...webkitAutofillStyles }
                      }}
                      InputLabelProps={{
                        sx: { fontSize: 14 }
                      }}
                    // disabled={tabView === 'outgoing'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="invoice_data.supplier_vat_number"
                  label="Supplier VAT Number"
                  error={touched.invoice_data?.supplier_vat_number && !!errors.invoice_data?.supplier_vat_number}
                  helperText={touched.invoice_data?.supplier_vat_number && errors.invoice_data?.supplier_vat_number}
                  onChange={handleChange}
                  value={values.invoice_data?.supplier_vat_number || ''}
                  sx={{ marginTop: '10px' }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{
                    sx: { fontSize: 14 },
                  }}
                // disabled={tabView === 'outgoing'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="invoice_data.reference_id"
                  label="Reference ID"
                  error={touched.invoice_data?.reference_id && !!errors.invoice_data?.reference_id}
                  helperText={touched.invoice_data?.reference_id && errors.invoice_data?.reference_id}
                  onChange={handleChange}
                  value={values.invoice_data?.reference_id || ''}
                  sx={{ marginTop: '10px' }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{
                    sx: { fontSize: 14 },
                  }}
                // disabled={tabView === 'outgoing'}
                />
              </Grid>
            </Grid>}

            {/* Right column for Invoice Details */}
            <Grid item xs={12} md={6}>
              <Typography sx={{ paddingBottom: "5px", paddingTop: "8px" }} variant="h6">
                {t("Invoice Details")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="invoice_data.invoice_number"
                    label="Invoice Number"
                    error={touched.invoice_data?.invoice_number && !!errors.invoice_data?.invoice_number}
                    helperText={touched.invoice_data?.invoice_number && errors.invoice_data?.invoice_number}
                    onChange={handleChange}
                    value={values.invoice_data?.invoice_number || ''}
                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    disabled={tabView === 'outgoing' ? true : false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="invoice_data.date"
                    label="Date"
                    error={touched.invoice_data?.date && !!errors.invoice_data?.date}
                    helperText={touched.invoice_data?.date && errors.invoice_data?.date}
                    onChange={handleChange}
                    value={values.invoice_data?.date || ''}
                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="invoice_data.due_date"
                    label="Due Date"
                    error={touched.invoice_data?.due_date && !!errors.invoice_data?.due_date}
                    helperText={touched.invoice_data?.due_date && errors.invoice_data?.due_date}
                    onChange={handleChange}
                    value={values.invoice_data?.due_date || ''}
                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="invoice_data.performance_period"
                  label={t("Performance Period")}
                  error={touched.invoice_data?.performance_period && !!errors.invoice_data?.performance_period}
                  helperText={touched.invoice_data?.performance_period && errors.invoice_data?.performance_period}
                  onChange={handleChange}
                  value={values.invoice_data?.performance_period || ''}
                  sx={{ marginTop: '10px' }}
                  InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                  InputLabelProps={{ sx: { fontSize: 14 } }}
                />
              </Grid>
            </Grid>


            {tabView === "outgoing" && <Grid item xs={12} md={12}>
              <Typography sx={{ paddingBottom: "5px", paddingTop: "8px" }} variant="h6">
                {t("Pricing Details")}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        name="invoice_data.total"
                        label={t("Total")}
                        error={touched.invoice_data?.total && !!errors.invoice_data?.total}
                        helperText={touched.invoice_data?.total && errors.invoice_data?.total}
                        onChange={handleChange}
                        value={values.invoice_data?.total || ''}
                        InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                        InputLabelProps={{
                          sx: { fontSize: 14 },
                        }}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "20px" }}> 
                  <ProductTable />
                </Grid>
              </Grid>
            </Grid>}
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography sx={{ paddingBottom: "5px", paddingTop: "20px" }} variant="h6">
              {t("General Details")}
            </Typography>
            <Grid container spacing={2}>
              {/* Left column */}
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      name="invoice_data.payment_terms"
                      label={t("Payment Terms")}
                      error={touched.invoice_data?.payment_terms && !!errors.invoice_data?.payment_terms}
                      helperText={touched.invoice_data?.payment_terms && errors.invoice_data?.payment_terms}
                      onChange={handleChange}
                      value={values.invoice_data?.payment_terms || ''}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Middle column */}
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      name="invoice_data.deal_terms"
                      label={t("Deal Terms")}
                      error={touched.invoice_data?.deal_terms && !!errors.invoice_data?.deal_terms}
                      helperText={touched.invoice_data?.deal_terms && errors.invoice_data?.deal_terms}
                      onChange={handleChange}
                      value={values.invoice_data?.deal_terms || ''}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      name="invoice_data.description"
                      label={t("Description")}
                      error={touched.invoice_data?.description && !!errors.invoice_data?.description}
                      helperText={touched.invoice_data?.description && errors.invoice_data?.description}
                      onChange={handleChange}
                      value={values.invoice_data?.description || ''}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Right column */}
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      name="invoice_data.comments"
                      label={t("Comment")}
                      error={touched.invoice_data?.comments && !!errors.invoice_data?.comments}
                      helperText={touched.invoice_data?.comments && errors.invoice_data?.comments}
                      onChange={handleChange}
                      value={values.invoice_data?.comments || ''}
                      InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                      InputLabelProps={{
                        sx: { fontSize: 14 },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
            <Button type="submit" disabled={formStatus === "loading"}>
              Create Invoice
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default CreateInvoice;