import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { RefObject, useState } from "react";
import { useTranslatedModalTitle } from "../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../context/LanguageContext";
import ExportAsImage from "../../../SmallComponents/ExportAs/ExportAsImage";
import Modal from "../../../MaterialUI/Modal";
import {
  WidgetGridItem,
  WidgetGridItemLayout,
} from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import Button from "../../../MaterialUI/Button";
import { ExtractChartConfig, OEEChartOptions, OEEGridItemData, OEEGridLayoutSettings } from "./oeeDashboardUtils";
import EditDynamicOEEChartForm from "./EditDynamicOEEChartForm";
import { AutocompleteOption } from "../../../../Global/Types/commonTypes";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const modalTitleTranslations = {
  "Edit layout configuration": "Edit layout configuration",
  "Confirm Delete": "Confirm Delete",
  "Edit configuration": "Edit configuration",
} as const;

type ModalTitle = keyof typeof modalTitleTranslations;

interface OEEItemRightMenuProps {
  widgetItem: WidgetGridItem<OEEGridItemData>;
  widgetIndex: number;
  setWidgetItems: React.Dispatch<React.SetStateAction<WidgetGridItem<OEEGridItemData>[]>>;
  settings: OEEGridLayoutSettings | null;
  handleCloseMenu: () => void;
  widID: string;
  handleUpdateWidget: (widID: string) => void;
  chartRef: RefObject<HTMLDivElement>;
  handleUpdateChartConfig: <K extends keyof OEEChartOptions>(
    widgetId: string,
    chartKey: K,
    updatedConfig: Partial<ExtractChartConfig<NonNullable<OEEChartOptions[K]>>>
  ) => void;
  assetTypeOptions: AutocompleteOption[];
}

const OEEItemRightMenu: React.FC<OEEItemRightMenuProps> = ({
  widgetItem,
  widgetIndex,
  setWidgetItems,
  settings,
  handleCloseMenu,
  widID,
  handleUpdateWidget,
  chartRef,
  assetTypeOptions,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const { t } = useLanguageContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  console.log("widgetItem", widgetItem);

  const handleEdit = () => {
    setModalTitle("Edit configuration");
  };

  const handleCopy = () => {
    const newId = uuidv4().split("-")[0];
    const position = {
      x: 0,
      y: Infinity,
    };

    const updatedLayout: WidgetGridItemLayout = Object.keys(widgetItem.layout).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: {
            ...widgetItem.layout[curr as keyof WidgetGridItemLayout],
            i: newId,
            ...position,
          },
        };
      },
      {} as WidgetGridItemLayout
    );

    const newItem: WidgetGridItem<OEEGridItemData> = {
      ...widgetItem,
      ...(widgetItem?.id && { id: uuidv4() }),
      title: `${widgetItem.title} - Copy`,
      layout: updatedLayout,
    };
    setWidgetItems((prev) => [newItem, ...prev]);
    handleUpdateWidget(widID);
    handleCloseMenu();
  };

  const handleRemoveWidget = () => {
    setModalTitle("Confirm Delete");
  };

  const handleConfirmRemove = () => {
    setWidgetItems((prev) => {
      const arr = [...prev];
      arr.splice(widgetIndex, 1);
      return arr;
    });
    setModalTitle(null);
    handleCloseMenu();
  };

  const handleLockWidget = () => {
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            isLocked: !item?.isLocked,
          };
        }
        return item;
      });
    });
    handleCloseMenu();
  };

  const handleEditWidgetSubmitForm = (
    updatedChart: OEEChartOptions | null,
    title: string
  ) => {
    if (widgetIndex === null) {
      throw new Error("Invalid item index");
    }
    if (!updatedChart) {
      throw new Error("Invalid updatedChart");
    }
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            widget: {
              ...item.widget,
              oeeChart: {
                ...updatedChart,
              },
            },
            title: title,
            loading: !item.loading,
          };
        }
        return item;
      });
    });
    handleUpdateWidget(widID);
    setModalTitle(null);
    setUnsavedChanges(false);
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    handleCloseMenu();
    setModalTitle(null);
  };

  return (
    <>
      <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
        <Stack alignItems="center">
          <IconButton 
           aria-label="edit widget configuration"
           onClick={handleEdit}
           disabled={!settings?.can_edit}
           >
            <EditOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Edit")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
           aria-label="copy widget" 
           onClick={handleCopy}
           disabled={!settings?.can_edit}
           >
            <DifferenceOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Copy")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label={widgetItem.layout.xl.isResizable ? "lock" : "unlock"}
            onClick={handleLockWidget}
          >
            <LockOpenOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {!widgetItem.layout.xl.isResizable ? t("Unlock") : t("Lock")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton 
          aria-label="delete"
           onClick={handleRemoveWidget}
           disabled={!settings?.can_delete}
           >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Delete")}
          </Typography>
        </Stack>
        <ExportAsImage
          isPrint
          elementRef={chartRef}
          fileName={widgetItem.title}
          iconButton={
            <Stack style={{ zIndex: 5 }} alignItems="center">
              <IconButton aria-label="open export menu">
                <PrintOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                Print
              </Typography>
            </Stack>
          }
        />
        <ExportAsImage
          elementRef={chartRef}
          fileName={widgetItem.title}
          iconButton={
            <Stack style={{ zIndex: 5 }} alignItems="center">
              <IconButton aria-label="open export menu">
                <FileDownloadOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                Export
              </Typography>
            </Stack>
          }
        />
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={modalTitle === "Edit configuration" ? "md" : "sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalTitle === "Confirm Delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete the widget")}
            </Typography>
            <Button onClick={handleConfirmRemove}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}

        {modalTitle === "Edit configuration" ? (
          <EditDynamicOEEChartForm
            chart={widgetItem.widget.oeeChart}
            widgetTitle={widgetItem.title}
            handleSaveChanges={handleEditWidgetSubmitForm}
            assetTypeOptions={assetTypeOptions}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OEEItemRightMenu;
