import {
    WIDGET_GRID_HEADING_MENU_HEIGHT,
    WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
    WidgetGridItem,
  } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
  import { css } from "@emotion/react";
  import useTheme from "@mui/material/styles/useTheme";
  import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
  import cssComponentsStyles from "../../../Global/Styles/components";
  import cssLayoutStyles from "../../../Global/Styles/layout";
  import cssSpacingStyles from "../../../Global/Styles/spacing";
  import ContentBox from "../../MaterialUI/ContentBox";
  import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
  import LazyRender from "../../SmallComponents/LazyRender/LazyRender";
  import { useState } from "react";
  import { addDays, format, isToday, subDays } from "date-fns";
  import ChartPeriodViewer from "../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
  import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
  import { PeriodModeDates } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
  import { TimePeriodType } from "../../PageComponents/OEE/oEEUtils";
  import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
  
  
  const cssStyles = {
    dragIcon: css({
      cursor: "grab",
      minWidth: "24px",
    }),
    staticItem: css({
      "& + #grid-resize-handle": {
        display: "none",
      },
    }),
    headingMenu: css({
      height: WIDGET_GRID_HEADING_MENU_HEIGHT,
      marginBottom: WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
    }),
    headingItem: css({
      width: "33.3%",
    }),
  };
  
  type GridItemGeneric = Record<string, any>;
  
  interface OEEWidgetsGridItemProps<T extends GridItemGeneric> {
    gridItem: WidgetGridItem<T>;
    rightMenu?: JSX.Element | JSX.Element[];
    canMove?: boolean;
    isStatic?: boolean;
    index: number;
    isUpdatingWidget: boolean;
    render: (
        item: WidgetGridItem<T>,
        index: number,
        isUpdatingWidget: boolean,
        period?: TimePeriodType | null,
        dates?: PeriodModeDates | null,
        date?: string | null,
      ) => JSX.Element | JSX.Element[]; 
  }
  
  const getFirstProp = (obj: any) => obj[Object.keys(obj)[0]]
  
  const OEEWidgetsGridItem: React.FC<OEEWidgetsGridItemProps<GridItemGeneric>> = ({
    gridItem,
    rightMenu,
    canMove,
    isStatic,
    index,
    isUpdatingWidget,
    render
  }) => {
    const theme = useTheme();
    const styles = {
      ...cssStyles,
      ...cssLayoutStyles,
      ...cssSpacingStyles(theme),
      ...cssComponentsStyles(theme),
    };
    const oeeChart = getFirstProp(gridItem.widget);
    const chart = getFirstProp(oeeChart);
    const chartConfig = chart.config
  
    const [period, setPeriod] = useState<TimePeriodType | null>(chartConfig.period || "M");
    const [dates, setDates] = useState<PeriodModeDates | null>({
      startDate: new Date(chartConfig.startDate),
      endDate: new Date(chartConfig.endDate),
    });
    const [date, setDate] = useState<string>(new Date().toISOString())
    const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  
    const toggleChartPeriodViewer = () => {
      setIsChartPeriodViewerVisible((prev) => !prev);
    };

    const getDatesPickerComponent = () => {
        if (!!chartConfig.period) {
            return (
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2} marginRight={2}>
                        {isChartPeriodViewerVisible && dates ? (
                            <Grid item>
                            <Stack alignItems="flex-end">
                                <ChartPeriodViewer
                                dates={dates || {startDate: new Date(), endDate: new Date()}}
                                setDates={setDates}
                                period={period}
                                setPeriod={setPeriod}
                                />
                            </Stack>
                            </Grid>
                        ) : dates ? (
                            <Grid item>
                            <Typography variant="body2" color={theme.palette.grey[700]}>
                                {`${format((dates as any).startDate, "dd.MM.yyyy")} - ${format(
                                (dates as any).endDate,
                                "dd.MM.yyyy"
                                )}`}
                            </Typography>
                            </Grid>
                        ) : null}
            
                        {!isStatic && <Grid item>
                            <IconButton
                            aria-label="toggle period viewer"
                            onClick={toggleChartPeriodViewer}
                            size="small"
                            >
                                <SwapHorizIcon
                                    css={styles.greyIcon}
                                    style={{
                                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                                    }}
                                />
                                </IconButton>
                        </Grid>}
                    </Grid>
            )
        } else if(!!chartConfig.date) { 
            const handleNextDay = () => {
                const nextDay = addDays(new Date(date), 1)
                setDate(nextDay.toISOString());
            }

            const handlePrevDay = () => {
                const nextDay = subDays(new Date(date), 1)
                setDate(nextDay.toISOString());
            }

            return (
                <Grid container justifyContent={isStatic ? "flex-end" : "flex-end"} alignItems="center">
                <Grid item mr={2}>
                  {!isStatic && <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <IconButton aria-label="previous day" onClick={handlePrevDay} size="small">
                        <ArrowBackIosIcon css={styles.greyIcon} fontSize="small" />
                      </IconButton>
                    </Grid>
                    {<Grid item>
                      <DatePicker
                        label=""
                        value={new Date(date)}
                        onChange={(val) => {
                          if (val) {
                            setDate(val.toISOString());
                          }
                        }}
                      />
                    </Grid>}
                    <Grid item>
                      <IconButton
                        aria-label="next day"
                        onClick={handleNextDay}
                        size="small"
                        disabled={isToday(new Date(date))}
                      >
                        <ArrowForwardIosIcon css={styles.greyIcon} fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>}
                </Grid>
                {isStatic && 
                <Grid item>
                  <Typography variant="body2" color={theme.palette.grey[700]}>
                    {`${format(new Date(date), "dd.MM.yyyy")}`}
                  </Typography>
                </Grid>}
              </Grid>
            )
        }

        return null;
    }
  
    return (
      <Box component="div" css={[styles.height100, gridItem.isLocked && styles.staticItem]}>
        <ContentBox css={[styles.height100, styles.fullPadding1]}>
          {isStatic ? null : (
            <Stack
              css={styles.headingMenu}
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box component="div" sx={{width: "45%"}} />
  
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                {gridItem.isLocked ? (
                  <Box component="div" />
                ) : (
                  <Box component="div" style={!canMove ? { display: "none" } : undefined}>
                    <DragHandleOutlinedIcon
                      sx={{
                        width: "33%",
                      }}
                      css={[styles.dragIcon, styles.greyIcon]}
                      className="drag-handle"
                    />
                  </Box>
                )}
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "45%",
                }}
              >
                {getDatesPickerComponent()}
                {rightMenu}
              </Box>
            </Stack>
          )}
  
          <Box
            sx={{
              width: "100%",
              height: isStatic ? "100%" : "calc(100% - 10px)",
            }}
            component="div"
          >
            <LazyRender delay={2000}>{render(gridItem, index, isUpdatingWidget, period, dates)}</LazyRender>
          </Box>
        </ContentBox>
      </Box>
    );
  };
  
  export default OEEWidgetsGridItem;
  