import { Query } from "../../../Api/callApi";
import { InvoiceType } from "../../../Components/PageComponents/Finance/Invoice/InvoiceTypes";

export const getQueryInvoices = (
  invoice_type: InvoiceType
): Query => ({
  endpoint: `payments_and_receivables/invoices`,
  method: "GET",
  variables: invoice_type,
});

export const getQueryExportIncomingInvoices = (
): Query => ({
  endpoint: `payments_and_receivables/invoices/incoming-export`,
  method: "GET",
  returnJson: false,
});

export const getQueryExportOutgoingInvoices = (
): Query => ({
  endpoint: `payments_and_receivables/invoices/outgoing-export`,
  method: "GET",
  returnJson: false,
});

export const getQuerySupplierNames = (
): Query => ({
  endpoint: `parties/parties`,
  method: "GET",
});

export const getQueryStatusInvoices = (
): Query => ({
  endpoint: `payments_and_receivables/invoices/statuses`,
  method: "GET",
});

export const getQueryFileFromId = (
  file_id: string
): Query => ({
  endpoint: `payments_and_receivables/invoices/preview/${file_id}`,
  method: "GET",
  returnJson: false,
});

export const getQueryLatestSupplier = (): Query => ({
  endpoint: `payments_and_receivables/invoices/suppliers/latest`,
  method: "GET",
});

// export const getQueryAllMaterials = (): Query => ({ // old
//   endpoint: `procurement_and_materials/materials/material-master-data`,
//   method: "GET",
// })

export const getQueryAllMaterials = (): Query => ({
  endpoint: `payments_and_receivables/materials/table`,
  method: "GET",
})

export const getQueryInvoiceNumeration = (): Query => ({
  endpoint: `payments_and_receivables/invoices/numeration/next`,
  method: "GET",
})

export const getQuerySuppliers = (): Query => ({
  endpoint: `parties/suppliers`,
  method: "GET",
})

export const getQueryCustomers = (): Query => ({ // get all customers
  endpoint: `parties/parties`,
  method: "GET",
})

export const getQueryOurInformation = (customer_id: string): Query => ({ // invoices customer information (us)
  endpoint: `parties/customers/${customer_id}`,
  method: "GET",
})

export const getQueryOurSupplier = (supplier_id: string): Query => ({ // outgoing supplier information (us)
  endpoint: `parties/customers/${{supplier_id}}`,
  method: "GET",
})