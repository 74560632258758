import { FileWithPath } from "react-dropzone";
import { Query } from "../../../Api/callApi";

export type File = {
  file: FileWithPath;
};
export const postQueryUploadInvoice = (
  input: any
): Query => ({
  endpoint: `payments_and_receivables/invoices`,
  method: "POST",
  variables: input,
  // multipartForm: true,
});

export const deleteQueryInvoice = (id: string): Query => ({
  endpoint: `payments_and_receivables/invoices/${id}`,
  method: "DELETE",
});

export const readQueryInvoice = (input: any): Query => ({
  endpoint: `payments_and_receivables/invoices/read`,
  method: "POST",
  variables: input,
  multipartForm: true
});

export const readNewSupplier = (input: any): Query => ({
  endpoint: `parties/suppliers`,
  method: "POST",
  variables: input,
  // multipartForm: true
});

export const uploadFile = (document_id: any): Query => ({
  endpoint: `payments_and_receivables/invoices/upload/${document_id}`,
  method: "POST",
  // multipartForm: true
});