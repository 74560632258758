import { SerializedStyles } from "@emotion/react";
import { DynamicGridOEEChartOptions } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { ChartXScaleMinMax } from "../../../ExcellenceWidgets/TimeChart/timeChartTypes";
import OEEBarChartLossesByPeriod from "./OEEBarChartLossesByPeriod/OEEBarChartLossesByPeriod";
import OEEBarChartAssetByHour from "./OEEBarChartAssetByHour/OEEBarChartAssetByHour";
import OEEBarChartForAssetByType from "./OEEBarChartForAssetByType/OEEBarChartForAssetByType";
import OEEBarChartForAvailability from "./OEEBarChartForAvailability/OEEBarChartForAvailability";
import OEEBarChartLossesByType from "./OEEBarChartLossesByType/OEEBarChartLossesByType";
import OEEBarChartPareto from "./OEEBarChartPareto/OEEBarChartPareto";
import OEEBulletChart from "./OEEBulletChart/OEEBulletChart";
import OEELineChartForSingleAsset from "./OEELineChartForSingleAsset/OEELineChartForSingleAsset";
import OEERadialChart from "./OEERadialChart/OEERadialChart";

interface StaticOEEWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  oeeChart: DynamicGridOEEChartOptions;
  chartWithLiveData?: DynamicGridOEEChartOptions;
  isStatic?: boolean;
  chartAnimate?: boolean;
  xScaleMinMax?: ChartXScaleMinMax;
  widgetID: string;
}

const StaticOEEWidget: React.FC<StaticOEEWidgetProps> = ({
  className,
  oeeChart,
  isStatic
}) => {
  // Excellence widgets
  if (oeeChart.assetByHourBarChart) {
    return (
      <OEEBarChartAssetByHour
        className={className}
        config={oeeChart.assetByHourBarChart.config}
        isStatic={isStatic}
        date={oeeChart.assetByHourBarChart.config.date}
      />
    );
  }
  if (oeeChart.barChartForAssetByType) {
    return (
      <OEEBarChartForAssetByType
        className={className}
        config={oeeChart.barChartForAssetByType.config}
        isStatic={isStatic}
        period={oeeChart.barChartForAssetByType.config.period}
      />
    );
  }
  if (oeeChart.availabilityBarChart) {
    return (
      <OEEBarChartForAvailability
        className={className}
        config={oeeChart.availabilityBarChart.config}
        isStatic={isStatic}
        period={oeeChart.availabilityBarChart.config.period || null}
      />
    );
  }
  if (oeeChart.barChartLossesByPeriod) {
    return (
      <OEEBarChartLossesByPeriod
        className={className}
        config={oeeChart.barChartLossesByPeriod.config}
        isStatic={isStatic}
        period={oeeChart.barChartLossesByPeriod.config.period}
      />
    );
  }
  if (oeeChart.barChartLossesByType) {
    return (
      <OEEBarChartLossesByType
        className={className}
        config={oeeChart.barChartLossesByType.config}
        isStatic={isStatic}
        period={oeeChart.barChartLossesByType.config.period}
      />
    );
  }
  if (oeeChart.paretoBarChart) {
    return (
      <OEEBarChartPareto
        className={className}
        config={oeeChart.paretoBarChart.config}
        isStatic={isStatic}
        period={oeeChart.paretoBarChart.config.period}
      />
    );
  }
  if (oeeChart.bulletChart) {
    return (
      <OEEBulletChart
        className={className}
        data={oeeChart.bulletChart.data}
        config={oeeChart.bulletChart.config}
        isStatic={isStatic}
      />
    );
  }
  if (oeeChart.lineChartForAsset) {
    return (
      <OEELineChartForSingleAsset
        className={className}
        config={oeeChart.lineChartForAsset.config}
        isStatic={isStatic}
        period={oeeChart.lineChartForAsset.config.period}
      />
    );
  }
  if (oeeChart.radialChart) {
    return (
      <OEERadialChart
        className={className}
        config={oeeChart.radialChart.config}
        isStatic={isStatic}
        period={oeeChart.radialChart.config.period}
      />
    );
  }
  // if (chart.timeChart) {
  //   return (
  //     <ExcellenceTimeChart
  //       className={className}
  //       timeChart={chart.timeChart}
  //       isStatic
  //       chartMode={null}
  //       loading={false}
  //       titleComponent={null}
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.barChart) {
  //   return (
  //     <ExcellenceBarChart
  //       className={className}
  //       configuration={chart.barChart.config}
  //       data={chart.barChart.data}
  //       schema={chart.barChart.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.columnChart) {
  //   return (
  //     <ExcellenceBarChart
  //       className={className}
  //       configuration={chart.columnChart.config}
  //       data={chart.columnChart.data}
  //       schema={chart.columnChart.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.table) {
  //   return (
  //     <ExcellenceTable
  //       className={className}
  //       configuration={chart.table.config}
  //       data={chart.table.data}
  //       schema={chart.table.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       widgetID={widgetID}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.pieChart) {
  //   return (
  //     <ExcellencePieChart
  //       className={className}
  //       configuration={chart.pieChart.config}
  //       data={chartWithLiveData?.pieChart?.data || chart.pieChart.data}
  //       schema={chart.pieChart.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       isDonut={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.donutChart) {
  //   return (
  //     <ExcellencePieChart
  //       className={className}
  //       configuration={chart.donutChart.config}
  //       data={chartWithLiveData?.donutChart?.data || chart.donutChart.data}
  //       schema={chart.donutChart.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       isDonut
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.radarChart) {
  //   return (
  //     <ExcellenceRadarChart
  //       className={className}
  //       configuration={chart.radarChart.config}
  //       data={chart.radarChart.data}
  //       isStatic={isStatic}
  //       schema={chart.radarChart.dataSchema}
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.areaChart) {
  //   return (
  //     <ExcellenceAreaChart
  //       className={className}
  //       configuration={chart.areaChart.config}
  //       data={chart.areaChart.data}
  //       isStatic={isStatic}
  //       schema={chart.areaChart.dataSchema}
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.textWidget) {
  //   return (
  //     <TextWidget
  //       className={className}
  //       configuration={chart.textWidget.config}
  //       data={chart.textWidget.dataSchema?.content || ""}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.gaugeWidget) {
  //   return (
  //     <GaugeWidget
  //       className={className}
  //       configuration={chart.gaugeWidget.config}
  //       data={chart.gaugeWidget.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.indicatorWidget) {
  //   return (
  //     <ExcellenceIndicator
  //       className={className}
  //       configuration={chart.indicatorWidget.config}
  //       data={chartWithLiveData?.indicatorWidget?.data || chart.indicatorWidget.data}
  //       schema={chart.indicatorWidget.dataSchema}
  //       isStatic
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }

  // // Advanced widgets
  // if (chart.boxPlot) {
  //   return (
  //     <ExcellenceBoxPlot
  //       className={className}
  //       configuration={chart.boxPlot.config}
  //       data={chart.boxPlot.data}
  //       isStatic
  //       schema={chart.boxPlot.dataSchema}
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.heatMap) {
  //   return (
  //     <ExcellenceHeatmap
  //       className={className}
  //       configuration={chart.heatMap.config}
  //       data={chart.heatMap.data}
  //       isStatic
  //       schema={chart.heatMap.dataSchema}
  //       resetChartFetch={false}
  //       tempFilters={[]}
  //     />
  //   );
  // }
  // if (chart.calendar) {
  //   return (
  //     <CalendarWidget
  //       className={className}
  //       configuration={chart.calendar.config}
  //       data={chart.calendar.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.funnel) {
  //   return (
  //     <Funnel
  //       className={className}
  //       configuration={chart.funnel.config}
  //       data={chart.funnel.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.network) {
  //   return (
  //     <Network
  //       className={className}
  //       configuration={chart.network.config}
  //       data={chart.network.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.sankey) {
  //   return (
  //     <Sankey
  //       className={className}
  //       configuration={chart.sankey.config}
  //       data={chart.sankey.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.scatterPlot) {
  //   return (
  //     <ScatterPlot
  //       className={className}
  //       configuration={chart.scatterPlot.config}
  //       data={chart.scatterPlot.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.sunburst) {
  //   return (
  //     <Sunburst
  //       className={className}
  //       configuration={chart.sunburst.config}
  //       data={chart.sunburst.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.swarmPlot) {
  //   return (
  //     <SwarmPlot
  //       className={className}
  //       configuration={chart.swarmPlot.config}
  //       data={chart.swarmPlot.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.timeRange) {
  //   return (
  //     <TimeRange
  //       className={className}
  //       configuration={chart.timeRange.config}
  //       data={chart.timeRange.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }
  // if (chart.treeMap) {
  //   return (
  //     <TreeMap
  //       className={className}
  //       configuration={chart.treeMap.config}
  //       data={chart.treeMap.data}
  //       isStatic={isStatic}
  //     />
  //   );
  // }

  return null;
};

export default StaticOEEWidget;
