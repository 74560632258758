import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  calculateAveragePercentage,
  getDatesForPeriod,
  LineChartForAssetConfigType,
  OEESingleAssetDataType,
  TimePeriodType,
} from "../../oEEUtils";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { GetQueryOEEChartByAsset } from "../../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../../Api/callApi";
import { getOEEByAsset } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { extractUnitSymbol } from "../../../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";
import { SerializedStyles } from "@emotion/react";
import { format } from "date-fns";
import CustomResponsiveLine from "../../CustomResponsiveLine";

interface OEELineChartForSingleAssetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: LineChartForAssetConfigType;
  onUpdateConfig?: (updatedConfig: Partial<LineChartForAssetConfigType>) => void;
  isStatic?: boolean;
  period: TimePeriodType | null
}

const OEELineChartForSingleAsset: React.FC<OEELineChartForSingleAssetProps> = ({
  config,
  onUpdateConfig,
  isStatic,
  period
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<OEESingleAssetDataType[]>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const gridLineColor =
    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900];

  useEffect(() => {
    if(onUpdateConfig) {
      const dates = getDatesForPeriod(period || "month")

      onUpdateConfig({
        period: period,
        startDate: new Date(dates.start).toISOString(),
        endDate: new Date(dates.end).toISOString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);

      if (config?.startDate && config.endDate) {
        const formattedStartDate = format(new Date(config.startDate), "yyyy-MM-dd");
        const formattedEndDate = format(new Date(config.endDate), "yyyy-MM-dd");
        const data = await callApi<GetQueryOEEChartByAsset>({
          query: getOEEByAsset(config.assetId, formattedStartDate, formattedEndDate),
          auth: { setAuthedUser },
        });

        setBarChartData(data.chart_data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const markers: any =
    config.high === null && config.low === null
      ? []
      : [
          ...(config.low !== null
            ? [
                {
                  axis: "y" as const,
                  legend: t("Low"),
                  legendPosition: "right",
                  lineStyle: {
                    stroke: "#BD4F4F",
                    strokeWidth: 1,
                  },
                  textStyle: {
                    fill: "#BD4F4F",
                    fontSize: "13px",
                  },
                  value: config.low,
                },
              ]
            : []),
          ...(config.high !== null
            ? [
                {
                  axis: "y" as const,
                  legend: t("High"),
                  legendPosition: "right",
                  lineStyle: {
                    stroke: "#33A161",
                    strokeWidth: 1,
                  },
                  textStyle: {
                    fill: "#33A161",
                    fontSize: "13px",
                  },
                  value: config.high,
                },
              ]
            : []),
        ];

  return (
    <Box component="div" ml={2}>
      <Stack alignItems="flex-end" mr={1} mt={1}>
        <Typography variant="h2">
          {calculateAveragePercentage(barChartData)?.percentage?.toFixed(2)}%
        </Typography>
      </Stack>

      {barChartData.length > 0 ? (
        <Box component="div" height="240px">
          <CustomResponsiveLine
            isInteractive={!isStatic}
            data={barChartData}
            margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
            enablePoints={false}
            enableGridX={false}
            colors={"#387CB8"}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
              grid: {
                line: {
                  stroke: gridLineColor,
                  strokeWidth: 1,
                },
              },
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => `${value}${extractUnitSymbol(config.unit || "")}`,
            }}
            lineWidth={2.5}
            markers={markers}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("Loading...")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEELineChartForSingleAsset;
