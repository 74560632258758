export type Invoice = {
  _id?: string;
  currency: string;
  customer_name: string;
  customer_vat_number: string;
  date: string;
  discount: number | null | string;
  due_date: string;
  invoice_number: string;
  net_total: number | null | string;
  reference_id: string;
  purchase_order_id: string;
  quantity: number;
  sales_order_id: string;
  status: string;
  supplier_name: string;
  supplier_vat_number: string;
  total_excise_amount: number | null | string;
  unit_price: number | null | string;
  vat_amount: number | null | string;
  actions?: React.ReactNode;
  total: string | number;
  document_id: string;
  file_name: string;
  performance_period: string;
  supplier_matches?: {
    id: string;
    name: string;
    score: number;
    tax_id: string;
  }[],
}

export type EditInvoice = {
  document_id: string;
  actions?: React.ReactNode;
  invoice_data: {
    invoice_type: string;
    invoice_number: string;
    date: string;
    net_total: number | null | string;
    discount: number | null | string;
    supplier_id: string;
    supplier_name: string;
    supplier_vat_number: string;
    customer_id: string;
    customer_name: string;
    customer_vat_number: string;
    description: string;
    quantity: number;
    unit_price: number | null | string;
    vat_amount: number | null | string;
    total_excise_amount: number | null | string;
    currency: string;
    deal_terms: string;
    comments: string;
    reference_id: string;
    purchase_order_id: string;
    sales_order_id: string;
    status: string;
    due_date: string;
    total: string | number;
    file_name: string;
    payment_terms?: string,
    bank_account?: string,
    issue_place?: string,
    created_by?: string,
    receiver?: string,
    performance_period?: string,
    products: {
      product_id: string;
      quantity: number;
    }[];
  };
};

export const modalTitleTranslations = {
  "Create new Invoice": "Create new Invoice",
  "Upload File": "Upload File",
} as const;

export type ModalTitle = keyof typeof modalTitleTranslations;

export type InvoiceType = {
  invoice_type: "incoming" | "outgoing"
};

export const webkitAutofillStyles = { // removing the auto-fill background color
  '& input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 30px white inset !important',
    '-webkit-text-fill-color': '#000000 !important',
  },
};