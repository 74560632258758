import { Box } from '@mui/material';
import { ResponsiveBar, ResponsiveBarSvgProps } from '@nivo/bar';
import { useMemo, useRef, useState, useEffect } from 'react';

interface CustomResponsiveBarProps extends ResponsiveBarSvgProps<any> {
  labelWidth?: number;
  labelPadding?: number; 
  height?: string;
}

export const CustomResponsiveBar = ({ 
  labelWidth = 6,
  labelPadding = 10,
  height,
  indexBy,
  data,
  margin = { top: 50, right: 50, bottom: 50, left: 50 },
  ...props 
}: CustomResponsiveBarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const { rotation, bottomMargin } = useMemo(() => {
    if (!data?.length) return { rotation: 0, bottomMargin: margin.bottom };

    const availableWidth = containerWidth - (margin.left || 50) - (margin.right || 50);
    const widthPerBar = availableWidth / data.length;

    const maxLabelLength = Math.max(
      ...data.map(d => String(d[indexBy as string] || '').length)
    );
    const totalLabelWidth = maxLabelLength * labelWidth;

    if (widthPerBar >= totalLabelWidth + labelPadding) {
      return { rotation: 0, bottomMargin: margin.bottom };
    }

    const angle = Math.acos(widthPerBar / (totalLabelWidth + labelPadding));
    const degrees = Math.min(Math.ceil(angle * (180 / Math.PI)), 90);
    
    // Adjust bottom margin based on rotation
    const rotatedHeight = Math.sin(degrees * (Math.PI / 180)) * totalLabelWidth;
    const newBottomMargin = (margin.bottom || 50) + rotatedHeight;
    
    return { 
      rotation: -degrees, 
      bottomMargin: Math.ceil(newBottomMargin)
    };
  }, [containerWidth, data, indexBy, labelWidth, labelPadding, margin]);

  return (
    <Box component="div" ref={containerRef} style={{ height: height || "100%" }}>
      <ResponsiveBar
        data={data}
        indexBy={indexBy}
        margin={{ ...margin, bottom: bottomMargin }}
        axisBottom={{
          tickRotation: rotation,
          tickPadding: Math.abs(rotation) > 0 ? 15 : 5,
          ...props.axisBottom
        }}
        {...props}
      />
    </Box>
  );
};

export default CustomResponsiveBar