import { Box, IconButton, Menu } from "@mui/material";
import { DynamicGridItemData } from "../../Components/SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { AutocompleteOption, FormStatuses } from "../../Global/Types/commonTypes";
import { useOutletContext, useParams } from "react-router-dom";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import { AppRouterProps } from "../../Layout/layoutVariables";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { WidgetGridItem } from "../../Components/LargeComponents/WidgetsGrid/widgetsGridUtils";
import WidgetsGridLayout from "../../Components/LargeComponents/WidgetsGrid/WidgetsGridLayout";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import cssComponentsStyles from "../../Global/Styles/components";
import { ErrorBoundary } from "react-error-boundary";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import {
  getQueryAssetsFunctionalLocations,
  getQueryOEEFullDashboard,
} from "../../Api/OEE/apiOEEGetQueries";
import {
  GetQueryOEEAseetsFunctionalLocationsSnippet,
  GetQueryOEEFullDashboardSnippet,
} from "../../Api/OEE/apiOEESnippets";
import {
  ExtractChartConfig,
  OEEChartOptions,
  OEEGridItemData,
  OEEGridLayoutSettings,
  oeeHandlePrepareWidgetsData,
} from "../../Components/PageComponents/OEE/OEEDashboardCharts/oeeDashboardUtils";
import OEEDashboardExtraPageMenu from "../../Components/PageComponents/OEE/OEEDashboardCharts/OEEDashboardExtraPageMenu";
import OEEChartItem from "../../Components/PageComponents/OEE/OEEDashboardCharts/OEEChartItem";
import OEEItemRightMenu from "../../Components/PageComponents/OEE/OEEDashboardCharts/OEEItemRightMenu";
import { getAssetsOptions } from "../../Components/PageComponents/OEE/oEEUtils";

type SelectedItem = {
  item: WidgetGridItem<OEEGridItemData>;
  index: number;
  width: number;
  height: number;
} | null;

const OEEDashboard: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [settings, setSettings] = useState<OEEGridLayoutSettings | null>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null); // initial loading
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [initialGridItems, setInitialGridItems] = useState<DynamicGridItemData[]>([]);
  const [widgetItems, setWidgetItems] = useState<WidgetGridItem<OEEGridItemData>[]>([]);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(null);
  const [updatedWidget, setUpdatedWidget] = useState<string | null>(null);
  const [assetTypeOptions, setAssetTypeOptions] = useState<AutocompleteOption[]>([]);

  const widgetRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();

  console.log("updatedWidget ", updatedWidget);

  const openMenu = Boolean(menuAnchor);

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage("Loading...");

        fetchDashboard();

        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect on mount err ", err);
        setPageStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    // Check for changes in gridItems and set unsavedChanges to true if it's changed
    const items = widgetItems.map((item) => removeDataKey(item));
    const initialItems = initialGridItems.map((item) => removeDataKey(item));
    const areEqual = JSON.stringify(items) === JSON.stringify(initialItems);
    setUnsavedChanges(!areEqual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItems, initialGridItems]);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      if (settings) {
        setExtraTopNavMenu(<PageTitle title={settings.name} />);
        setExtraRightNavMenu(
          <OEEDashboardExtraPageMenu
            setWidgetItems={setWidgetItems}
            widgetItems={widgetItems}
            settings={settings}
            setUnsavedChanges={setUnsavedChanges}
            setSettings={setSettings}
            setInitialGridItems={setInitialGridItems}
            dashboardRef={dashboardRef}
          />
        );
      }
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, widgetItems, settings]);

  const fetchDashboard = async () => {
    if (!params.id) {
      throw new Error("ID from params not found for this excellence dashboard");
    }

    const data = await callApi<GetQueryOEEFullDashboardSnippet>({
      query: getQueryOEEFullDashboard(params.id),
      auth: { setAuthedUser },
    });

    setSettings({
      id: data.id,
      name: data.name,
      can_add: data.can_add,
      can_edit: data.can_edit,
      can_delete: data.can_delete
    });

    const items = oeeHandlePrepareWidgetsData(data.widgets);
    setWidgetItems(items);
    setInitialGridItems(items);
  };

  const handleUpdateWidgetsLayout = useCallback((data: WidgetGridItem<any>[]) => {
    setWidgetItems(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenItemMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: WidgetGridItem<any>,
    index: number,
    width: number,
    height: number
  ) => {
    setMenuAnchor(event.currentTarget);
    setSelectedItem({ item, index, width, height });
  };
  const handleCloseItemMenu = () => {
    setMenuAnchor(null);
    setSelectedItem(null);
  };

  const handleUnlockWidget = (widgetIndex: number) => {
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            isLocked: !item?.isLocked,
          };
        }
        return item;
      });
    });
  };

  function handleUpdateChartConfig<K extends keyof OEEChartOptions>(
    widgetId: string,
    chartKey: K,
    updatedConfig: Partial<ExtractChartConfig<NonNullable<OEEChartOptions[K]>>>
  ) {
    setWidgetItems((prev) =>
      prev.map((item) => {
        if (item.layout.lg.i === widgetId) {
          const chart = item.widget.oeeChart;
          if (!chart) return item;

          const existingChart = chart[chartKey];
          if (
            existingChart &&
            typeof existingChart === "object" &&
            "config" in (existingChart as Record<string, unknown>)
          ) {
            const chartWithConfig = existingChart as { config: object };
            return {
              ...item,
              widget: {
                ...item.widget,
                oeeChart: {
                  ...chart,
                  [chartKey]: {
                    ...chartWithConfig,
                    config: {
                      ...chartWithConfig.config,
                      ...updatedConfig,
                    },
                  },
                },
              },
            };
          }
        }
        return item;
      })
    );
  }

  const fetchOEEData = async () => {
    try {
      const oEEAssets = await callApi<GetQueryOEEAseetsFunctionalLocationsSnippet>({
        query: getQueryAssetsFunctionalLocations,
        auth: { setAuthedUser },
      });
      setAssetTypeOptions(getAssetsOptions(oEEAssets));
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  return (
    <Box component="div">
      {smMediaQuery && settings ? (
        <OEEDashboardExtraPageMenu
          setWidgetItems={setWidgetItems}
          widgetItems={widgetItems}
          settings={settings}
          setUnsavedChanges={setUnsavedChanges}
          setSettings={setSettings}
          setInitialGridItems={setInitialGridItems}
          dashboardRef={dashboardRef}
        />
      ) : null}

      <Box component="div" ref={dashboardRef}>
        {/* TODO: below alert isn't shown at the moment */}
        <Alert
                css={[styles.widthLimit25]}
                message={alertMessage}
                showAlert={false}
                severity={pageStatus}
              />

              {smMediaQuery ? (
                settings?.name ? (
                  <PageTitle css={styles.labelBreak} title={settings.name} />
                ) : null
              ) : null}

              {settings ? (
                <>
                  <WidgetsGridLayout
                    dataItems={widgetItems}
                    updateDataItems={handleUpdateWidgetsLayout}
                    rowHeight={150}
                    canMove={settings.can_add || settings.can_edit}
                    isOEELayout={true}
                    renderRightMenu={(item, index, width, height) => (
                      <>
                        {item.isLocked ? (
                          <IconButton
                            aria-label="unlock item"
                            onClick={() => handleUnlockWidget(index)}
                          >
                            <LockOutlinedIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="widget setting menu"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                              handleOpenItemMenu(e, item, index, width, height)
                            }
                          >
                            <TuneOutlinedIcon css={styles.greyIcon} />
                          </IconButton>
                        )}
                      </>
                    )}
                    render={(item, index, isUpdatingWidget, period, dates, date) => (
                      <ErrorBoundary
                        FallbackComponent={() => (
                          <Box component="div">
                            <Alert severity="error" message="Something went wrong" showAlert />
                          </Box>
                        )}
                      >
                        <Box
                          ref={index === selectedItem?.index ? widgetRef : null}
                          style={
                            isUpdatingWidget
                              ? { display: "none", width: "100%", height: "100%" }
                              : {
                                  width: "100%",
                                  height: "100%",
                                }
                          }
                          component="div"
                        >
                          <OEEChartItem
                            chart={item.widget.oeeChart}
                            title={item.title}
                            widgetId={item.layout.lg.i || ""}
                            handleUpdateChartConfig={handleUpdateChartConfig}
                    dates={dates}
                    period={period}
                    date={date}
                          />
                        </Box>
                      </ErrorBoundary>
                    )}
                  />

                  <Menu
                    anchorEl={menuAnchor}
                    open={openMenu}
                    onClose={handleCloseItemMenu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {selectedItem ? (
                      <OEEItemRightMenu
                        widgetItem={selectedItem.item}
                        widgetIndex={selectedItem.index}
                        setWidgetItems={setWidgetItems}
                settings={settings}
                        handleCloseMenu={handleCloseItemMenu}
                        widID={selectedItem?.item?.layout?.lg?.i || ""}
                        handleUpdateWidget={(wid) => setUpdatedWidget(wid)}
                        chartRef={widgetRef}
                        handleUpdateChartConfig={handleUpdateChartConfig}
                        assetTypeOptions={assetTypeOptions}
                      />
                    ) : null}
                  </Menu>
                </>
              ) : null}
      </Box>
      
    </Box>
  );
};

export default OEEDashboard;

function removeDataKey(obj: DynamicGridItemData): DynamicGridItemData {
  const result = structuredClone(obj);
  delete result.loading;
  for (const key in result.widget.oeeChart) {
    if (Object.prototype.hasOwnProperty.call(result.widget.oeeChart, key)) {
      // @ts-ignore
      delete result.widget.oeeChart[key as keyof DynamicGridOEEChartOptions]
        ?.data as unknown as DynamicGridItemData;
    }
  }
  return result;
}
