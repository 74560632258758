import { TextField, Grid, Container, Checkbox, FormControlLabel, Button, Divider, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useLanguageContext } from '../../../../context/LanguageContext';
import { useState } from 'react';
import callApi from '../../../../Api/callApi';
import { useAuthedContext } from '../../../../context/AuthContext';
import { readNewSupplier } from '../../../../pages/Finance/API/apiFinancePostQueries';
import * as yup from 'yup';
import { webkitAutofillStyles } from './InvoiceTypes';
import { YUP_REQUIRED_STRING } from '../../../../Global/Constants/yupConstants';

const fieldValidation = () => yup.object({
    registration_number: YUP_REQUIRED_STRING,
    company: YUP_REQUIRED_STRING,
    addresses: yup.array().of(
        yup.object({
            street_address: YUP_REQUIRED_STRING,
            city: YUP_REQUIRED_STRING,
            country: YUP_REQUIRED_STRING,
            postal_code: YUP_REQUIRED_STRING
        })
    ),
    // tax_id: yup.string().when([], {
    //     is: () => showAdditionalField,
    //     then: () => YUP_REQUIRED_STRING,
    //     otherwise: () => yup.string().notRequired(),
    // }),
});

interface CreateSupplierProps {
    onSubmit?: (values: CreateSupplierValues) => void;
    sendId?: (data: string) => void;
    sendTest?: (data: string) => void;
}

interface CreateSupplierValues {
    id: string;
    registration_number: string;
    manager_of_record: string;
    manager_of_record_en?: string;
    tax_id?: string;
    vat_registered: boolean;
    company: string;
    company_en?: string;
    lead_time_days: number;
    payment_terms: string;
    default_currency_id: string;
    addresses: {
        street_address: string;
        street_address_en?: string;
        street_address_2: string;
        street_address_2_en?: string;
        city: string;
        city_en?: string;
        country: string;
        country_en?: string;
        address_type: string;
        state: string;
        postal_code: string;
        is_primary: boolean;
    }[];
    contacts: {
        name: string;
        name_en?: string;
        phone?: string;
        email?: string;
        contact_type: string;
        position: string;
        position_en?: string;
        is_primary: boolean;
    }[];
};

const initialValues: CreateSupplierValues = {
    id: '',
    registration_number: '',
    manager_of_record: '',
    manager_of_record_en: '',
    tax_id: '',
    vat_registered: true,
    company: '',
    company_en: '',
    lead_time_days: 0,
    payment_terms: '',
    default_currency_id: '982a96a6-62ee-4a4b-b757-69ee2e36de6a',
    addresses: [
        {
            street_address: '',
            street_address_en: '',
            street_address_2: '',
            street_address_2_en: '',
            city: '',
            city_en: '',
            country: '',
            country_en: '',
            address_type: '',
            state: '',
            postal_code: '',
            is_primary: true,
        },
    ],
    contacts: [
        {
            name: '',
            name_en: '',
            phone: '',
            email: '',
            contact_type: '',
            position: '',
            position_en: '',
            is_primary: true,
        },
    ],
};


const CreateSupplier: React.FC<CreateSupplierProps> = ({ onSubmit, sendId, sendTest }) => {
    const { t } = useLanguageContext();
    const { setAuthedUser } = useAuthedContext();

    const [showAdditionalField, setShowAdditionalField] = useState(false);

    const transformValues = (values: CreateSupplierValues) => {
        return {
            registration_number: values.registration_number,
            manager_of_record: values.manager_of_record,
            tax_id: values.tax_id,
            vat_registered: values.vat_registered,
            company: values.company,
            lead_time_days: values.lead_time_days,
            payment_terms: values.payment_terms,
            default_currency_id: values.default_currency_id,
            manager_of_record_en: values.manager_of_record_en,
            company_en: values.company_en,
            id: values.id,
            address: values.addresses[0],
            contact: values.contacts[0],
        };
    };

    const handleFormSubmit = async (values: CreateSupplierValues) => {
        try {
            const transformedData = transformValues(values);
            const response = await callApi<any>({
                query: readNewSupplier(transformedData),
                auth: { setAuthedUser },
            });

            if (onSubmit) {
                onSubmit(response);

                if (sendId) {
                    sendId(response.id);
                }

                if (sendTest) {
                    sendTest(response.manager_of_record);
                }
            }

        } catch (err) {
            console.error('Error submitting form:', err);
        }
    };

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={fieldValidation}
            >
                {({ handleSubmit, handleChange, touched, errors, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="registration_number"
                                    label={t('Registration Number')}
                                    error={touched["registration_number"] && !!errors["registration_number"]}
                                    helperText={touched["registration_number"] && errors["registration_number"]}
                                    onChange={handleChange}
                                    value={values.registration_number}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="contacts[0].email"
                                    label={t('Email')}
                                    error={
                                        touched.contacts?.[0]?.email &&
                                        !!(errors.contacts && Array.isArray(errors.contacts) && typeof errors.contacts[0] === 'object' && errors.contacts[0]?.email)
                                    }
                                    helperText={touched.contacts?.[0]?.email && typeof errors.contacts?.[0] === 'object' && errors.contacts[0]?.email}
                                    onChange={handleChange}
                                    value={values.contacts[0].email}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="tax_id"
                                    label={t('Tax ID')}
                                    error={touched["tax_id"] && !!errors["tax_id"]}
                                    helperText={touched["tax_id"] && errors["tax_id"]}
                                    onChange={handleChange}
                                    value={values.tax_id}
                                    disabled={!showAdditionalField}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="contacts[0].phone"
                                    label={t('Phone')}
                                    error={
                                        touched.contacts?.[0]?.phone &&
                                        !!(errors.contacts && Array.isArray(errors.contacts) && typeof errors.contacts[0] === 'object' && errors.contacts[0]?.phone)
                                    }
                                    helperText={touched.contacts?.[0]?.phone && typeof errors.contacts?.[0] === 'object' && errors.contacts[0]?.phone}
                                    onChange={handleChange}
                                    value={values.contacts[0].phone}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showAdditionalField}
                                            onChange={(e) => setShowAdditionalField(e.target.checked)}
                                        />
                                    }
                                    label={t('Регистрация по ДДС')}
                                    sx={{
                                        fontSize: 13, '& .MuiFormControlLabel-label': { fontSize: 13 },
                                        position: 'absolute',
                                        top: '30px',
                                        right: '85px'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider
                            sx={{
                                marginTop: "30px",
                                marginBottom: "15px",
                            }}
                        />

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">
                                    {t("Supplier Details")}
                                </Typography>

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="company"
                                    label={t('Име на фирмата (BG)')}
                                    error={touched["company"] && !!errors["company"]}
                                    helperText={touched["company"] && errors["company"]}
                                    onChange={handleChange}
                                    value={values.company}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].street_address"
                                    label={t('Адрес (BG)')}
                                    error={touched.addresses?.[0]?.street_address && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.street_address)}
                                    helperText={touched.addresses?.[0]?.street_address && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.street_address}
                                    onChange={handleChange}
                                    value={values.addresses[0].street_address}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].street_address_2"
                                    label={t('Допълнителен адрес (BG)')}
                                    error={touched.addresses?.[0]?.street_address_2 && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.street_address_2)}
                                    helperText={touched.addresses?.[0]?.street_address_2 && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.street_address_2}
                                    onChange={handleChange}
                                    value={values.addresses[0].street_address_2}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].postal_code"
                                    label={t('Пощенски код (BG)')}
                                    error={touched.addresses?.[0]?.postal_code && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.postal_code)}
                                    helperText={touched.addresses?.[0]?.country && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.postal_code}
                                    onChange={handleChange}
                                    value={values.addresses[0].postal_code}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].city"
                                    label={t('Населено място (BG)')}
                                    error={touched.addresses?.[0]?.city && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.city)}
                                    helperText={touched.addresses?.[0]?.city && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.city}
                                    onChange={handleChange}
                                    value={values.addresses[0].city}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].country"
                                    label={t('Държава (BG)')}
                                    error={touched.addresses?.[0]?.country && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.country)}
                                    helperText={touched.addresses?.[0]?.country && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.country}
                                    onChange={handleChange}
                                    value={values.addresses[0].country}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="manager_of_record"
                                    label={t('МОЛ (BG)')}
                                    error={touched["manager_of_record"] && !!errors["manager_of_record"]}
                                    helperText={touched["manager_of_record"] && errors["manager_of_record"]}
                                    onChange={handleChange}
                                    value={values.manager_of_record}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="company_en"
                                    label={t('Name of Company (EN)')}
                                    error={touched["company_en"] && !!errors["company_en"]}
                                    helperText={touched["company_en"] && errors["company_en"]}
                                    onChange={handleChange}
                                    value={values.company_en}
                                    sx={{ marginTop: '29px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].street_address_en"
                                    label={t('Address (EN)')}
                                    error={touched.addresses?.[0]?.street_address_en && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.street_address_en)}
                                    helperText={touched.addresses?.[0]?.street_address_en && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.street_address_en}
                                    onChange={handleChange}
                                    value={values.addresses[0].street_address_en}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].street_address_2_en"
                                    label={t('Additional address (EN)')}
                                    error={touched.addresses?.[0]?.street_address_2_en && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.street_address_2_en)}
                                    helperText={touched.addresses?.[0]?.street_address_2_en && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.street_address_2_en}
                                    onChange={handleChange}
                                    value={values.addresses[0].street_address_2_en}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label={t('Postal Code (EN)')}
                                    value={values.addresses[0].postal_code}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                    disabled={true}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].city_en"
                                    label={t('City (EN)')}
                                    error={touched.addresses?.[0]?.city_en && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.city_en)}
                                    helperText={touched.addresses?.[0]?.city_en && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.city_en}
                                    onChange={handleChange}
                                    value={values.addresses[0].city_en}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="addresses[0].country_en"
                                    label={t('Country (EN)')}
                                    error={touched.addresses?.[0]?.country_en && !!(errors.addresses && Array.isArray(errors.addresses) && typeof errors.addresses[0] === 'object' && errors.addresses[0]?.country_en)}
                                    helperText={touched.addresses?.[0]?.country_en && typeof errors.addresses?.[0] === 'object' && errors.addresses[0]?.country_en}
                                    onChange={handleChange}
                                    value={values.addresses[0].country_en}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    name="manager_of_record_en"
                                    label={t('Manager of Record (EN)')}
                                    error={touched["manager_of_record_en"] && !!errors["manager_of_record_en"]}
                                    helperText={touched["manager_of_record_en"] && errors["manager_of_record_en"]}
                                    onChange={handleChange}
                                    value={values.manager_of_record_en}
                                    sx={{ marginTop: '10px' }}
                                    InputProps={{ sx: { fontSize: 14, ...webkitAutofillStyles } }}
                                    InputLabelProps={{ sx: { fontSize: 14 } }}
                                />

                            </Grid>
                        </Grid>

                        <Grid item sx={{ textAlign: 'center', marginTop: 4 }}>
                            <Button variant="contained" type="submit">
                                {t('Create Supplier')}
                            </Button>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Container>
    );
};

export default CreateSupplier;