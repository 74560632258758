import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper, useTheme } from '@mui/material';
import { postQueryAIChatGenerate } from '../../../../Api/AI/apiAIPostQueries';
import callApi from '../../../../Api/callApi';
import { useAuthedContext } from '../../../../context/AuthContext';
import { PostQueryAIChatGenerateSnippet } from '../../../../Api/AI/apiAISnippets';

interface AIDataAnalyzerProps {
    isStatic?: boolean;
} 

const AIDataAnalyzer: React.FC<AIDataAnalyzerProps> = ({isStatic}) => {
    const { setAuthedUser } = useAuthedContext();
    const theme = useTheme()

    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState<PostQueryAIChatGenerateSnippet>({response: "", type: ""});
    const [isLoading, setIsLoading] = useState(false);

    const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value);
    };

    const handleSubmit = async () => {
        if (!prompt.trim()) return;

        setIsLoading(true);
        try {
        const result = await callApi<PostQueryAIChatGenerateSnippet>({
            query: postQueryAIChatGenerate(prompt),
            auth: { setAuthedUser },
        });
        setResponse(result);
        } catch (error) {
        setResponse({response: "I'm sorry, I don't have enough information to answer that question. Could you please rephrase or provide more details?", type: "string"})
        console.error('Error fetching response:', error);
        } finally {
        setIsLoading(false);
        }
    };

    return (
        <Box component="div" sx={{ 
                maxWidth: 600,
                margin: 'auto',
                mt: 2, 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                pointerEvents: isStatic ? "none" : "auto"
                }}>
            <TextField
                fullWidth
                variant="outlined"
                label="Enter your prompt"
                value={prompt}
                onChange={handlePromptChange}
                disabled={isLoading}
                sx={{ mb: 1 }}
            />
            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isLoading || !prompt.trim()}
                sx={{ mb: 1, width: "100px" }}
            >
                Ask
            </Button>
            <Box component="div" sx={{overflowY: "auto"}}>
                {(response?.response && response?.type !== "error") && (
                    response.type === "string"
                    ?
                        <Paper elevation={1} sx={{ p: 2, my: 2, backgroundColor: theme.palette.secondary.light }}>
                            <Typography variant="body1">{response.response}</Typography>
                        </Paper>
                    :
                        (
                            <Box
                            component="img"
                            sx={{
                            width: '80%',
                            maxWidth: 600,
                            height: 'auto',
                            display: 'block',
                            margin: 'auto',
                            }}
                            alt="AI Generated Chart"
                            src={`https://${response.response}?t=${new Date().getTime()}`}
                        />
                        )
                )}
            </Box>
        </Box>
    );
};

export default AIDataAnalyzer;