import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  assetDataColors,
  BarChartAssetByHoursConfigType,
  calculateWorkingAndNonWorkingMinutes,
  HourData,
  TransformedHourData,
} from "../../oEEUtils";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import { format } from "date-fns";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import callApi from "../../../../../Api/callApi";
import { GetQueryOEEAssetStatusHourly } from "../../../../../Api/OEE/apiOEESnippets";
import { getAssetStatusHourly } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";
import CustomResponsiveBar from "../../CustomResponsiveBar";

interface OEEBarChartAssetByHourProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: BarChartAssetByHoursConfigType;
  onUpdateConfig?: (updatedConfig: Partial<BarChartAssetByHoursConfigType>) => void;
  isStatic?: boolean;
  date: string | null;
}

const OEEBarChartAssetByHour: React.FC<OEEBarChartAssetByHourProps> = ({
  config,
  onUpdateConfig,
  isStatic,
  date
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<HourData[]>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const chartDate = isStatic || !date ? new Date() : date

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);

      const formattedDate = format(new Date(chartDate), "yyyy-MM-dd");
      const data = await callApi<GetQueryOEEAssetStatusHourly>({
        query: getAssetStatusHourly(config.assetId, formattedDate),
        auth: { setAuthedUser },
      });

      setBarChartData(data.chart_data);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if(onUpdateConfig && date) {
      onUpdateConfig({ date: date });
    }
  })

  const transformedData = barChartData.map((entry) => {
    const transformedEntry: TransformedHourData = { hour: entry.hour };
    entry.values.forEach((item, index) => {
      transformedEntry[`${item.category} ${index + 1}`] = item.value / 60;
    });
    return transformedEntry;
  });

  const sortedData = transformedData.slice().sort((a, b) => a.hour.localeCompare(b.hour));

  return (
    <Box component="div" ml={2}>
      {barChartData.length > 0
        ? sortedData.map((entry, index) => {
            const entryKeys = Object.keys(entry).filter((key) => key !== "hour");
            const { actualWorkingMinutes, maxPossibleWorkingMinutes } =
              calculateWorkingAndNonWorkingMinutes(entry);

            const height = index === 0 || index === sortedData.length - 1 ? "45px" : "25px"

            return (
              <Box
                component="div"
                key={index}
                height={height}
              >
                <CustomResponsiveBar
                  isInteractive={!isStatic}
                  height={height}
                  data={[entry]}
                  keys={entryKeys}
                  indexBy="hour"
                  margin={{
                    top: index === 0 ? 20 : 0,
                    right: 60,
                    bottom: index === sortedData.length - 1 ? 20 : 0,
                    left: 40,
                  }}
                  layout="horizontal"
                  colors={({ id }) =>
                    assetDataColors[
                      (id as string).split(" ")[0] as keyof typeof assetDataColors
                    ]
                  }
                  theme={{
                    text: {
                      fill: theme.palette.common.black,
                    },
                    tooltip: {
                      container: {
                        background: theme.palette.common.white,
                      },
                    },
                  }}
                  padding={0.2}
                  label={({ id }) =>
                    id.toString().includes("Working") ||
                    id.toString().includes("SpeedLoss")
                      ? ""
                      : id.toString().split(" ")[0]
                  }
                  tooltip={({ id, value }) => (
                    <div
                      style={{
                        padding: "3px 10px",
                        background: theme.palette.common.white,
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <strong>{id.toString().split(" ")[0]}</strong>:{" "}
                      {parseSecondsToHMS(value)}
                    </div>
                  )}
                  axisTop={
                    index === 0
                      ? {
                          tickValues: [15, 30, 45, 60],
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendPosition: "middle",
                          legendOffset: -36,
                        }
                      : null
                  }
                  axisRight={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 40,
                    format: () =>
                      `${actualWorkingMinutes} / ${maxPossibleWorkingMinutes}`,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                />
              </Box>
            );
          })
        : !alertMessage && (
            <Typography variant="body2" mt={2} mb={1}>
              {t("Loading...")}
            </Typography>
          )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartAssetByHour;
