import { Query } from "../callApi";

export const postQueryAIChatGenerate = (
  message: string,
): Query => ({
  endpoint: `ai/chat/generate`,
  method: "POST",
  variables: {
    message,
  }
});
