import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import { convertObjectKeysToCamelCase } from "../../../../Global/Utils/commonFunctions";
import { getQueryAllMaterials } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import { TableGridColumnSchema } from "../../../SmallComponents/TableGrid/constructTableGrid";
import { useEffect, useState, useCallback } from "react";
import ResponsiveTableGrid from "../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import Modal from "../../../MaterialUI/Modal";
import ProductTableCatalog from "./ProductTableCatalog";

type TableResponse = {
    columns: TableGridColumnSchema[],
    rows: Record<string, any>[]
}

interface ProductTableProps {
    loading?: boolean;
}

const ProductTable: React.FC<ProductTableProps> = ({
    loading,
}) => {
    const { setAuthedUser } = useAuthedContext();
    const [invoicesTable, setInvoicesTable] = useState<TableResponse>();
    const [data, setData] = useState<{ columns: TableGridColumnSchema[]; rows: Record<string, any>[] }>({
        columns: [],
        rows: [],
    });
    const [tableRows, setTableRows] = useState<Record<string, any>[]>([]);
    const [isProductCatalogModalOpen, setIsProductCatalogModalOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('');

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const table = await callApi<TableResponse>({
                query: getQueryAllMaterials(),
                auth: { setAuthedUser },
            });

            const columns = [...table.columns];

            if (!columns.find(col => col.id === 'quantity')) {
                columns.push({
                    id: 'quantity',
                    label: 'Quantity',
                    type: 'number'
                });
            }            

            const rows = table.rows.map(row => ({
                ...row,
                quantity: row.quantity
            }))

            setData({
                columns, rows
            });

            const newInvoices: any = convertObjectKeysToCamelCase({columns, rows});
            setInvoicesTable(newInvoices);

        } catch (error) {
            console.log(error)
        }
    };

    const handleProductSelect = useCallback((selectedProduct: Record<string, any>) => {
        const updatedRow = {
            name: selectedProduct.name,
            unit: selectedProduct.unit,
            id: selectedProduct.id,
            classification_id: selectedProduct.classification_id,
            price: selectedProduct.price,
            quantity: 1
        };

        setTableRows(prev => [...prev, updatedRow]);
    }, []);

    const handleOpenModal = () => {
        setModalTitle("Add new Product");
        setIsProductCatalogModalOpen(true);
    };

    const handleSaveRows = useCallback(async (rows: Record<string, any>[]) => {
        setTableRows(rows);
    }, []);

    const handleDeleteRow = useCallback((rowIndex: number) => {
        setTableRows(prev => {
            const newRows = [...prev];
            newRows.splice(rowIndex, 1);
            return newRows;
        });
    }, []);

    return (
        <>
            {invoicesTable && (
                <ResponsiveTableGrid
                    rows={tableRows}
                    setRows={setTableRows}
                    colSchema={data.columns}
                    editMode={true}
                    loading={loading}
                    responsive="responsive"
                    tableID="CreateInvoice_AddProduct_modal_table"
                    addInitialRow={true}
                    showAddFromCatalog={true}
                    onAddFromCatalog={handleOpenModal}
                    onSaveRows={handleSaveRows}
                    onDeleteRow={handleDeleteRow}
                />
            )}

            <Modal
                open={isProductCatalogModalOpen}
                onClose={() => setIsProductCatalogModalOpen(false)}
                maxWidth={modalTitle === "Add new Product" ? "lg" : "md"}
                label={modalTitle}
            >
                <ProductTableCatalog
                    loading={loading}
                    onSelectProduct={handleProductSelect}
                    onCloseModal={() => setIsProductCatalogModalOpen(false)}
                />
            </Modal>
        </>
    )
};

export default ProductTable;