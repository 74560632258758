import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  BarChartForAssetByTypeConfigType,
  BarChartForAssetsByTypeDataType,
  BarChartForAssetsDataType,
  chartTypeOptions,
  getChartColor,
  getDatesForPeriod,
  LossesChartType,
  TimePeriodType,
} from "../../oEEUtils";
import { ComputedDatum } from "@nivo/bar";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import Select from "../../../../MaterialUI/FormFields/Select";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { getOEELossesByAsset } from "../../../../../Api/OEE/apiOEEGetQueries";
import { GetQueryOEELossesByType } from "../../../../../Api/OEE/apiOEESnippets";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import CustomResponsiveBar from "../../CustomResponsiveBar";

interface OEEBarChartForAssetByTypeProps {
  height?: string,
  className?: string;
  config: BarChartForAssetByTypeConfigType;
  onUpdateConfig?: (updatedConfig: Partial<BarChartForAssetByTypeConfigType>) => void;
  isStatic?: boolean;
  period: TimePeriodType | null;
}

const DROPDOWN_HEIGHT = "45px"

const OEEBarChartForAssetByType: React.FC<OEEBarChartForAssetByTypeProps> = ({
  height,
  config,
  onUpdateConfig,
  isStatic,
  period
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<BarChartForAssetsByTypeDataType>([]);
  const [selectedLossesTypeChart, setSelectedLossesTypeChart] = useState<LossesChartType>(
    config.type
  );
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    if(onUpdateConfig) {
      const dates = getDatesForPeriod(period || "month")

      onUpdateConfig({
        period: period,
        startDate: new Date(dates.start).toISOString(),
        endDate: new Date(dates.end).toISOString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setAlertMessage(null);
      if (config?.startDate && config?.endDate) {
        const data = await callApi<GetQueryOEELossesByType>({
          query: getOEELossesByAsset(config.startDate, config.endDate, config.type),
          auth: { setAuthedUser },
        });
        
        setBarChartData(data.chart_data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleChangeType = (type: LossesChartType) => {
    if (onUpdateConfig) {
      onUpdateConfig({
        type: type,
      });
    }
  };

  const changeConfig = (type: LossesChartType) => {
    setSelectedLossesTypeChart(type);
    handleChangeType(type);
  };

  const keys =
    barChartData.length > 0
      ? Object.keys(barChartData[0]).filter((key) => key !== "asset")
      : [];

  return (
    <Box component="div" ml={2} sx={{height}}>
      <Grid container justifyContent="space-between" alignItems="center">
        {
        !isStatic ? 
          (<Grid item>
            <Select
              selectOptions={chartTypeOptions}
              value={selectedLossesTypeChart}
              label={t("Asssets Type")}
              onChange={(e) => changeConfig(e.target.value as LossesChartType)}
            />
          </Grid>) 
        :
          (<Grid item>
            <Typography variant="caption" color={theme.palette.grey[700]}>
                      Assets Type
            </Typography>
            <Typography variant="body1" color={theme.palette.common.black}>
                      {chartTypeOptions.find(type => type.value == selectedLossesTypeChart)?.description}
            </Typography>
          </Grid>)
        }
      </Grid>

      {barChartData.length > 0 ? (
        <Box component="div" sx={{height: (isStatic || !height) ? "250px" : `calc(100% - ${DROPDOWN_HEIGHT})`}}>
          <CustomResponsiveBar
            isInteractive={!isStatic}
            data={barChartData}
            keys={keys}
            indexBy="asset"
            margin={{ top: 20, right: 20, bottom: 22, left: 60 }}
            colors={({ id }: ComputedDatum<BarChartForAssetsDataType>) =>
              getChartColor(id as string)
            }
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            padding={0.2}
            valueFormat={(value) => parseSecondsToHMS(value)}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => parseSecondsToHMS(value),
            }}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t("Loading...")}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartForAssetByType;
