import { useState, useEffect } from "react";
import { useAuthedContext } from "../../../../context/AuthContext";
import { getQueryFileFromId } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import callApi from "../../../../Api/callApi";
import { Typography } from "@mui/material";

const ImagePreview = ({ file_id }: { file_id: string }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { setAuthedUser } = useAuthedContext();

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await callApi<any>({
                    query: getQueryFileFromId(file_id),
                    auth: { setAuthedUser }
                })

                if (!response.ok) {
                    throw new Error(`Failed to fetch image: ${response.statusText}`);
                }

                // Extract the image data as a Blob
                const imageBlob = await response.blob();

                // Create a temporary URL for the Blob
                const imageUrl = URL.createObjectURL(imageBlob);

                // Set the URL for the image preview
                setImageUrl(imageUrl);
            } catch (error) {
                console.error("Error fetching image:", error);
                setError("Failed to load image.");
            } finally {
                setLoading(false);
            }
        };

        fetchImage();

        // Cleanup function to revoke the object URL
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [file_id]);

    if (loading) {
        return <Typography variant="h5" sx={{marginBottom: "20px"}}>
            Loading image...
        </Typography>
    }

    if (error) {
        return <Typography variant="h5" sx={{marginBottom: "20px"}}>
            {error}
        </Typography>
    }

    return (
        <div>
            {imageUrl && (
                <>
                    <Typography variant="h5" sx={{marginBottom: "10px"}}>Click to preview invoice</Typography>
                    <a
                        href={imageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "block",
                            cursor: "pointer",
                            width: "110px",
                            height: "100px",
                            backgroundImage: `url(${imageUrl})`,
                            backgroundSize: "cover",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginBottom: "20px",
                            marginTop: "5px"
                        }}
                    ></a>
                </>
            )}
        </div>
    );
};

export default ImagePreview;