import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import { convertObjectKeysToCamelCase } from "../../../../Global/Utils/commonFunctions";
import { getQueryAllMaterials } from "../../../../pages/Finance/API/apiFinanceGetQueries";
import { TableGridColumnSchema } from "../../../SmallComponents/TableGrid/constructTableGrid";
import { useEffect, useState } from "react";
import ResponsiveTableGrid from "../../../SmallComponents/TableGrid/ResponsiveTableGrid";

type TableResponse = {
    columns: TableGridColumnSchema[],
    rows: Record<string, any>[]
}

interface ProductTableCatalogProps {
    onSelectProduct: (product: Record<string, any>) => void;
    onCloseModal: () => void;
    loading?: boolean;
}

const ProductTableCatalog: React.FC<ProductTableCatalogProps> = ({
    onSelectProduct,
    onCloseModal,
    loading,
}) => {
    const { setAuthedUser } = useAuthedContext();
    const [invoicesTable, setInvoicesTable] = useState<TableResponse>();
    const [data, setData] = useState<{ columns: TableGridColumnSchema[]; rows: Record<string, any>[] }>({
        columns: [],
        rows: [],
    });

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const table = await callApi<TableResponse>({
                query: getQueryAllMaterials(),
                auth: { setAuthedUser },
            });

            // const filteredColumns = table.columns.filter(column =>
            //     // ['name', 'material_code', 'unit', 'net_total', 'quantity', 'discount', 'vat', 'gross_total'].includes(column.id)
            //     ['name', 'net_total', 'quantity', 'unit', 'vat', 'gross_total'].includes(column.id)
            // )

            setData({
                columns: table.columns,
                rows: table.rows
            });

            const newInvoices: any = convertObjectKeysToCamelCase(table);
            setInvoicesTable(newInvoices);

        } catch (error) {
            console.log(error)
        }
    };

    const handleRowClick = (row: Record<string, any>) => {
        onSelectProduct(row);
        onCloseModal();
    };

    return (
        <>
            {invoicesTable && (
                <ResponsiveTableGrid
                    rows={data?.rows}
                    colSchema={data?.columns || []}
                    editMode={false}
                    loading={loading}
                    onRowClick={handleRowClick}
                    responsive="responsive"
                    tableID="CreateInvoice_AddProductCatalog_modal_table"
                />
            )}
        </>
    )
};

export default ProductTableCatalog;