import { Stack, IconButton, Typography, Menu, useTheme, Theme } from "@mui/material";
// import {
// ExcellenceGridItemData,
// excellenceHandlePrepareWidgetsData,
// } from "../../Components/PageComponents/Excellence/excellenceUtils";
import { css } from "@emotion/react";
import { RefObject, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
// import { PostQueryExcellenceWidgetsInput } from "../../Api/Excellence/apiExcellenceInputs";
// import { postQueryExcellenceWidgets } from "../../Api/Excellence/apiExcellencePostQueries";
// import {
//   ExcellenceWidget,
//   ExcellenceWidgetFirstItem,
//   PostQueryExcellenceWidgetsSnippets,
// } from "../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../../Api/callApi";
// import { Layout } from "react-grid-layout";
import { v4 as uuidv4 } from "uuid";
import { useTranslatedModalTitle } from "../../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../../context/LanguageContext";
import {
  DynamicGridItemData,
  DynamicGridOEEChartOptions,
} from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import ExportAsImage from "../../../SmallComponents/ExportAs/ExportAsImage";
import CustomIconsTooltip from "../../../SmallComponents/Tooltip/CustomIconsTooltip";
import Button from "../../../MaterialUI/Button";
import { WidgetGridItem } from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import {
  ModalTitle,
  modalTitleTranslations,
  OEEGridItemData,
  OEEGridLayoutSettings,
  oeeHandlePrepareWidgetsData,
} from "./oeeDashboardUtils";
import Modal from "../../../MaterialUI/Modal";
import UpdateOEEDashboard from "./UpdateOEEDashboard";
import { DeleteQueryOEEDashboard } from "../../../../Api/OEE/apiOEEInputs";
import {
  deleteQueryOEEDashboard,
  postQueryOEEWidgets,
} from "../../../../Api/OEE/apiOEEPostQueries";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import AddNewOEEChart from "./AddNewOEEChart";
import { Layout } from "react-grid-layout";
import {
  OEEWidget,
  OEEWidgetFirstItem,
  PostQueryOEEWidgetsInput,
  PostQueryOEEWidgetsSnippets,
} from "../../../../Api/OEE/apiOEESnippets";

const cssStyles = (theme: Theme) => ({
  addMenu: css({
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }),
});

interface OEEDashboardExtraPageMenuProps {
  setWidgetItems: React.Dispatch<React.SetStateAction<WidgetGridItem<OEEGridItemData>[]>>;
  widgetItems: WidgetGridItem<OEEGridItemData>[];
  settings: OEEGridLayoutSettings;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<OEEGridLayoutSettings | null>>;
  setInitialGridItems: React.Dispatch<React.SetStateAction<DynamicGridItemData[]>>;
  dashboardRef: RefObject<HTMLDivElement>;
}

const OEEDashboardExtraPageMenu: React.FC<OEEDashboardExtraPageMenuProps> = ({
  setWidgetItems,
  widgetItems,
  settings,
  setUnsavedChanges,
  setSettings,
  setInitialGridItems,
  dashboardRef,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [addNewMenuAnchor, setAddNewMenuAnchor] = useState<null | HTMLElement>(null);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);
  const [doOpenUnsavedChangesModal, setDoOpenUnsavedChangesModal] =
    useState<boolean>(false);

  const openAddNewMenu = Boolean(addNewMenuAnchor);
  const navigate = useNavigate();

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const handleOpenAddNewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddNewMenuAnchor(event.currentTarget);
  };
  const handleCloseAddNewMenu = () => {
    setAddNewMenuAnchor(null);
  };

  const handleAddNewWidget = (chart: DynamicGridOEEChartOptions, title: string) => {
    const newItemLayout = {
      i: uuidv4().split("-")[0],
      x: 0,
      y: 0,
      w: chart.radialChart ? 6 : 12,
      h: chart.bulletChart
        ? 1.5
        : chart.radialChart
        ? 3
        : chart.assetByHourBarChart
        ? 2.8
        : 2.5,
      isResizable: true,
      isBounded: false,
    };
    const newWidget: WidgetGridItem<OEEGridItemData> = {
      layout: {
        xl: newItemLayout,
        lg: newItemLayout,
        md: newItemLayout,
        sm: newItemLayout,
        xs: newItemLayout,
      },
      widget: {
        oeeChart: {
          ...chart,
        },
      },
      title,
    };

    // IMPORTANT!!! Keep the newly added item
    // as the first element of the array. Look at logic for
    // handleOnLayoutChange() in the <WidgetsGridLayout/> component
    setWidgetItems((prev) => [newWidget, ...prev]);
    handleCloseAddNewMenu();
  };

  const handleSaveWidgets = async () => {
    try {
      const firstItems = handleGetFirstWidget(widgetItems);

      const charts: OEEWidget[] = widgetItems.map((item, index) => {
        const { id, widget, ...other } = item;
        let formattedContent = { ...other, widget };
        const firstItemBreakpoint: OEEWidgetFirstItem = [];
        if (firstItems.firstDesktopItemIndex === index) {
          firstItemBreakpoint.push("desktop");
        }
        if (firstItems.firstMobileItemIndex === index) {
          firstItemBreakpoint.push("mobile");
        }

        return {
          content: formattedContent,
          firstItemBreakpoint: firstItemBreakpoint.length ? firstItemBreakpoint : null,
          ...(id && { id: id }),
        };
      });

      const saveChangesInput: PostQueryOEEWidgetsInput = {
        layoutID: settings.id,
        widgets: charts,
      };

      const result = await callApi<PostQueryOEEWidgetsSnippets>({
        query: postQueryOEEWidgets(saveChangesInput),
        auth: { setAuthedUser },
      });

      const preparedData = oeeHandlePrepareWidgetsData(result.widgets);
      setWidgetItems(preparedData);
      setInitialGridItems(preparedData);
      setUnsavedChanges(false);
    } catch (err) {
      console.log("err ", err);
    }
  };

  const handleDeleteLayout = async () => {
    try {
      setFormStatus("loading");
      const redirectURL = "/GIANT-Operations-Suite/OEE/Dashboard";

      const input: DeleteQueryOEEDashboard = settings.id;

      await callApi<string>({
        query: deleteQueryOEEDashboard(input),
        auth: { setAuthedUser },
      });

      setFormStatus(null);
      setUnsavedChanges(false);
      navigate({
        pathname: redirectURL,
      });
    } catch (err) {
      console.log("handleDeleteLayout() func ", err);
      setFormStatus("error");
    }
  };

  return (
    <>
      <Stack spacing={1} justifyContent="flex-end" direction="column">
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Add")}>
            <IconButton
              aria-label="add new widget"
              onClick={handleOpenAddNewMenu}
              disabled={formStatus === "loading" || !settings.can_add}
            >
              <AddOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Edit")}>
            <IconButton
              aria-label="edit layout settings"
              onClick={() => setModalTitle("Edit layout configuration")}
              disabled={formStatus === "loading" || !settings.can_edit}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Duplicate")}>
            <IconButton aria-label="duplicate dashboard" disabled>
              <FileCopyIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Select")}>
            <IconButton aria-label="select custom variant" disabled>
              <PanToolAltOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Delete")}>
            <IconButton
              aria-label="delete layout"
              onClick={() => setModalTitle("Confirm layout delete")}
              disabled={formStatus === "loading" || !settings.can_delete}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Save")}>
            <IconButton
              aria-label="save changes"
              onClick={handleSaveWidgets}
              disabled={formStatus === "loading" || !settings.can_edit}
            >
              <SaveOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <ExportAsImage fileName={settings.name} elementRef={dashboardRef} isPrint />
        <ExportAsImage fileName={settings.name} elementRef={dashboardRef} />
      </Stack>

      <Menu
        anchorEl={addNewMenuAnchor}
        open={openAddNewMenu}
        onClose={handleCloseAddNewMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <AddNewOEEChart
          css={[styles.addMenu, styles.labelBreak]}
          handleAddNewOEEChart={(chart, title) => handleAddNewWidget(chart, title)}
        />
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={"sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
        doOpenUnsavedChangesModal={doOpenUnsavedChangesModal}
        setDoOpenUnsavedChangesModal={setDoOpenUnsavedChangesModal}
      >
        {modalTitle === "Confirm layout delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete this dashboard")}
            </Typography>
            <Button onClick={handleDeleteLayout} loading={formStatus === "loading"}>
              {t("Confirm Delete")}
            </Button>
          </Stack>
        ) : null}

        {modalTitle === "Edit layout configuration" ? (
          <UpdateOEEDashboard
            settings={settings}
            handleCloseModal={() => setModalTitle(null)}
            setSettings={setSettings}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OEEDashboardExtraPageMenu;

const handleGetFirstWidget = (items: WidgetGridItem<OEEGridItemData>[]) => {
  const desktopLayout: Layout[] = [];
  const mobileLayout: Layout[] = [];
  for (const item of items) {
    desktopLayout.push(item.layout.xl);
    mobileLayout.push(item.layout.xs);
  }

  // Sort the items based on X coordinate first and then by Y coordinate
  desktopLayout.sort((a, b) => {
    if (a.x === b.x) {
      return a.y - b.y;
    }
    return a.x - b.x;
  });
  mobileLayout.sort((a, b) => {
    if (a.x === b.x) {
      return a.y - b.y;
    }
    return a.x - b.x;
  });

  // The leftmost item on the top row
  const firstDesktopItemIndex = items.findIndex(
    (item) => item.layout.xl.i === desktopLayout[0].i
  );
  const firstMobileItemIndex = items.findIndex(
    (item) => item.layout.xs.i === mobileLayout[0].i
  );

  return {
    firstDesktopItemIndex,
    firstMobileItemIndex,
  };
};
