import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AvailabilityBarChartConfig,
  getChartColor,
  getDatesForPeriod,
  OEEAssetChartType,
  TimePeriodType,
} from "../../oEEUtils";
import { parseSecondsToHMS } from "../../../../../Global/Utils/commonFunctions";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { GetQueryOEEAssetChart } from "../../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../../Api/callApi";
import { getAvailabilityLossByAsset } from "../../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import Alert from "../../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";
import CustomResponsiveBar from "../../CustomResponsiveBar";

interface OEEBarChartForAvailabilityProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  config: AvailabilityBarChartConfig;
  onUpdateConfig?: (updatedConfig: Partial<AvailabilityBarChartConfig>) => void;
  isStatic?: boolean;
  period: TimePeriodType | null;
}

const OEEBarChartForAvailability: React.FC<OEEBarChartForAvailabilityProps> = ({
  config,
  onUpdateConfig,
  isStatic,
  period
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<OEEAssetChartType>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useEffect(() => {
    if(onUpdateConfig) {
      const dates = getDatesForPeriod(period || "month")

      onUpdateConfig({
        period: period,
        startDate: new Date(dates.start).toISOString(),
        endDate: new Date(dates.end).toISOString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const fetchOEEData = async () => {
    try {
      setFormStatus(null);
      setFormStatus(null);
      setAlertMessage(null);

      if (config?.startDate && config.endDate) {
        const data = await callApi<GetQueryOEEAssetChart>({
          query: getAvailabilityLossByAsset(
            config.assetId,
            config.startDate,
            config.endDate
          ),
          auth: { setAuthedUser },
        });
        setBarChartData(data.chart_data);
      }
    } catch (error) {
      console.log("There was an error fetching assets ", error);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Box component="div" ml={2} height="90%">
      {barChartData.length > 0 ? (
        <Box component="div" height="90%">
          <CustomResponsiveBar
            isInteractive={!isStatic}
            height="100%"
            data={barChartData}
            keys={["workingTime"]}
            indexBy="date"
            margin={{ top: 20, right: 20, bottom: 30, left: 60 }}
            colors={getChartColor("Availability")}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            padding={0.2}
            valueFormat={(value: any) => parseSecondsToHMS(value)}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value: any) => parseSecondsToHMS(value),
            }}
          />
        </Box>
      ) : (
        !alertMessage && (
          <Typography variant="body2" mt={2} mb={1}>
            {t(
              "No losses have been assigned to this asset yet. Assign losses to populate the chart."
            )}
          </Typography>
        )
      )}
      <Box component="div" mt={1}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Box>
    </Box>
  );
};

export default OEEBarChartForAvailability;
